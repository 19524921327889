<template>
    <form @submit.prevent="updateSignature()">
        <div class="d-flex mt-5">
            <div class="small-3 columns ">
                <label class="right inline profile_labels">
                    {{
                    $t('PROFILE_SETTINGS.FORM.MESSAGE_SIGNATURE.LABEL')
                    }} :
                </label>
            </div>
            <div class="small-9 columns ml-5 d-flex">
                <div class="signature_section w-100">
                    <div class="textarea_wrapper w-100">
                        <woot-message-editor
                                id="message-signature-input"
                                v-model="messageSignature"
                                class="message-editor"
                                :is-format-mode="true"
                                :placeholder="$t('PROFILE_SETTINGS.FORM.MESSAGE_SIGNATURE.PLACEHOLDER')"
                                @blur="$v.messageSignature.$touch"
                        />
                        <button
                                :is-loading="isUpdating"
                                type="submit"
                                :is-disabled="$v.messageSignature.$invalid"
                                class="signature_btn cursor-pointer"
                        >
                            {{ $t('PROFILE_SETTINGS.FORM.MESSAGE_SIGNATURE_SECTION.APPLY') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex mt-2">
            <div class="small-3 columns ">
                <label class="right inline profile_labels">
                </label>
            </div>
            <div class="small-9 columns ml-5 d-flex">
                <div class="d-flex mt-2 signature_text">
                    {{ $t('PROFILE_SETTINGS.FORM.MESSAGE_SIGNATURE_SECTION.DESCRIPTION') }}
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';

import WootMessageEditor from 'dashboard/components/widgets/WootWriter/Editor';
import alertMixin from 'shared/mixins/alertMixin';
import WootButton from "../../../../components/ui/WootButton";
import Button from "../../../../../shared/components/Button";

export default {
    components: {
        Button,
        WootButton,
        WootMessageEditor,
    },
    mixins: [alertMixin],
    data() {
        return {
            messageSignature: '',
            enableMessageSignature: false,
            isUpdating: false,
            errorMessage: '',
        };
    },
    validations: {
        messageSignature: {
            required,
        },
    },
    computed: {
        ...mapGetters({
            currentUser: 'getCurrentUser',
            currentUserId: 'getCurrentUserID',
        }),
    },
    watch: {
        currentUser() {
            this.initValues();
        },
    },
    mounted() {
        this.initValues();
    },
    methods: {
        initValues() {
            const { message_signature: messageSignature } = this.currentUser;
            this.messageSignature = messageSignature || '';
        },
        async updateSignature() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.showAlert(this.$t('PROFILE_SETTINGS.FORM.ERROR'));
                return;
            }

            try {
                await this.$store.dispatch('updateProfile', {
                    message_signature: this.messageSignature,
                });
                this.errorMessage = this.$t(
                    'PROFILE_SETTINGS.FORM.MESSAGE_SIGNATURE_SECTION.API_SUCCESS'
                );
            } catch (error) {
                this.errorMessage = this.$t(
                    'PROFILE_SETTINGS.FORM.MESSAGE_SIGNATURE_SECTION.API_ERROR'
                );
                if (error?.response?.data?.message) {
                    this.errorMessage = error.response.data.message;
                }
            } finally {
                this.isUpdating = false;
                this.showAlert(this.errorMessage);
            }
        },
    },
};
</script>

<style lang="scss">
.profile--settings--row {
  .ProseMirror-woot-style {
    height: 8rem;
  }

  .editor-root {
    background: var(--white);
    margin-bottom: var(--space-normal);
  }
}
</style>
