<template>

  <!--############################ Block For Input Phone ########################################## This block is activated when the input type is 'input_phone' and options are present(Or Not). ############################################################################-->
  <div v-if="shouldDisableForPhone && !hasSubmittedValues && !isRecording">
    <!-- Add this condition -->
    <div class="centered">
      <chat-options
          :title="'without title'"
          :options="messageContentAttributes.items"
          :hide-fields="!!messageContentAttributes.submitted_values"
          @click="onOptionSelect"
      />
    </div>
    <!-- Display warning if showWarning flag is true -->
    <div v-if="showWarning" class="input-container">
      <div class="warning-box numbers">
        <svg xmlns="http://www.w3.org/2000/svg" class="warning-icon" viewBox="0 0 24 24">
          <rect fill="#FFEB3B" width="24" height="24" rx="4" ry="4"/>
          <path fill="#333" d="M12 15h2v2h-2zm0-8h2v6h-2zm0 10h2v2h-2z"/>
          <circle cx="12" cy="12" r="10" fill="none"/>
        </svg>
        <span class="warning-message">La valeur doit être égale à 8 digits.</span>
      </div>
    </div>
    <div class="icon-button flex items-center justify-start">
      <!-- Tunisia flag icon -->
      <img src="../../../../app/javascript/widget/assets/images/tunisie.png" alt="Tunisian Flag" class="flag-icon"/>
      <!-- +216 -->
      <span class="plus-code">+216</span>
      <textarea
          ref="textarea"
          :placeholder="''"
          :value="value"
          @input="onInput"
          @focus="onFocus"
          @keyup="onKeyup"
          @blur="onBlur"
          class="form-input user-message-input is-focused"
      />
      <!-- Phone call icon -->
      <img src="../../../../app/javascript/widget/assets/images/phone.png" alt="Tunisian Flag" class="flag-icon"/>
    </div>
  </div>


  <!--####################### Block For Input Numbers && OTP CODE ############################################# This block is activated when the input type is 'input_cin' and options are present(Or Not). ############################################################################-->

  <div
      v-else-if="(shouldDisableNumbers || shouldDisableOtp || shouldDisableOtpFDigits ) && !hasSubmittedValues && !isRecording ">
    <!-- Add this condition -->
    <div class="centered">
      <chat-options
          :title="'without title'"
          :options="messageContentAttributes.items"
          :hide-fields="!!messageContentAttributes.submitted_values"
          @click="onOptionSelect"
      />
    </div>
    <!-- Display warning if showWarning flag is true -->
    <div v-if="showWarning || shouldShowWarning" class="input-container">
      <div class="warning-box numbers">
        <svg xmlns="http://www.w3.org/2000/svg" class="warning-icon" viewBox="0 0 24 24">
          <rect fill="#FFEB3B" width="24" height="24" rx="4" ry="4"/>
          <path fill="#333" d="M12 15h2v2h-2zm0-8h2v6h-2zm0 10h2v2h-2z"/>
          <circle cx="12" cy="12" r="10" fill="none"/>
        </svg>
        <span class="warning-message">{{ getWarningMessage() }}</span>
      </div>
    </div>
    <div class="icon-button flex items-center justify-start">
    <textarea
        ref="textarea"
        :placeholder="''"
        :value="value"
        @input="onInput"
        @focus="onFocus"
        @keyup="onKeyup"
        @blur="onBlur"
        class="form-input user-message-input is-focused"
    />
      <!-- Number icon -->
      <img src="../../../../app/javascript/widget/assets/images/inputnumbers.png" alt="Tunisian Flag"
           class="flag-icon"/>
    </div>
  </div>

  <!--####################### Block For Input Price ############################################# This block is activated when the input type is 'input_price' and options are present(Or Not). ############################################################################-->

  <div v-else-if="ShouldDisablePrice && !hasSubmittedValues && !isRecording ">
    <!-- Add this condition -->
    <div class="centered">
      <chat-options
          :title="'without title'"
          :options="messageContentAttributes.items"
          :hide-fields="!!messageContentAttributes.submitted_values"
          @click="onOptionSelect"
      />
    </div>
    <!-- Display warning if showWarning flag is true -->
    <div v-if="showWarning || shouldShowWarning" class="input-container">
      <div class="warning-box numbers">
        <svg xmlns="http://www.w3.org/2000/svg" class="warning-icon" viewBox="0 0 24 24">
          <rect fill="#FFEB3B" width="24" height="24" rx="4" ry="4"/>
          <path fill="#333" d="M12 15h2v2h-2zm0-8h2v6h-2zm0 10h2v2h-2z"/>
          <circle cx="12" cy="12" r="10" fill="none"/>
        </svg>
        <span class="warning-message">{{ getWarningMessage() }}.</span>
      </div>
    </div>
    <div class="icon-button flex items-center justify-start">
    <textarea
        ref="textarea"
        :placeholder="''"
        :value="value"
        @input="onInput"
        @focus="onFocus"
        @keyup="onKeyup"
        @blur="onBlur"
        class="form-input user-message-input is-focused"
    />
      <!-- Phone call icon -->
      <img src="../../../../app/javascript/widget/assets/images/inputnumbers.png" alt="Tunisian Flag"
           class="flag-icon"/>
    </div>
  </div>


  <!--###################################################################### This block is activated when only options are present. ############################################################################-->

  <div
      v-else-if="shouldDisableForOptions && !shouldDisableForPhone && !hasSubmittedValues && contentType !=='cards_products' && !isRecording ">
    <!-- Use v-else-if for both conditions -->
    <div class="centered">
      <chat-options
          :title="'without title'"
          :options="messageContentAttributes.items"
          :hide-fields="!!messageContentAttributes.submitted_values"
          :content-type="contentType"
          :product-size="productsIDs.length"
          @click="onOptionSelect"
      />
    </div>
  </div>


  <!--###################################################################### This block is specifically for the retail sector when the content type is 'cards_products'. ############################################################################-->

  <div v-else-if="contentType ==='cards_products' && !isRecording "> <!-- Use v-else-if for both conditions -->
    <div class="centered">
      <chat-options
          :title="'without title'"
          :options="messageContentAttributes.items"
          :hide-fields="!!messageContentAttributes.submitted_values"
          :product-size="productsIDs.length"
          :content-type="contentType"
          @click="onOptionSelect"
      />
    </div>
  </div>


  <!--###################################################################### This block is exclusively for 'input_date' type inputs with options present (Or Not). ############################################################################-->
  <div v-else-if="shouldDisableDate && !hasSubmittedValues && !isRecording">
    <!-- Add this condition -->
    <div class="centered">
      <chat-options
          :title="'without title'"
          :options="messageContentAttributes.items"
          :hide-fields="!!messageContentAttributes.submitted_values"
          @click="onOptionSelect"
      />
    </div>
    <div>
      <input
          type="date"
          id="date-input"
          :value="initialDate"
          :min="initialDate"
          @change="onDateChange"
          @input="onInput"
          @focus="onFocus"
          @blur="onBlur"
          class="form-input user-message-input is-focused"
      />
    </div>
  </div>


  <!--###################################################################### This block is exclusively for 'input_duration' type inputs ############################################################################-->
  <div v-else-if="shouldDisableDuration && !hasSubmittedValues && !isRecording">
    <template>
      <div class="centered">
        <select
            v-model="selectedYear"
            id="year-dropdown"
            @change="onYearChange"
            @focus="onFocus"
            @blur="onBlur"
        >
          <option value="">{{ yearPlaceholder }}</option>
          <option v-for="n in years" :key="n" :value="n">{{ n }} ans</option>
        </select>
        <select
            v-model="selectedMonth"
            id="month-dropdown"
            @change="onMonthChange"
            @focus="onFocus"
            @blur="onBlur"
        >
          <option value="">Mois (1 à 12)</option>
          <option v-for="n in months" :key="n" :value="n">{{ n }} mois</option>
        </select>
      </div>
    </template>
  </div>

  <!--###################################################################### This block is dedicated to Voice Recording. ############################################################################-->

  <div v-else-if="isRecording">
    <WootAudioRecorder
        @state-recorder-changed="onStateRecorderChanged"
        @state-recorder-progress-changed="onStateProgressRecorderChanged"
        @finish-record="onFinishRecorder"
    />
  </div>
  <!--###################################################################### This block is dedicated to regular text inputs, commonly used in our widget. ############################################################################-->
  <div v-else>
    <textarea
        ref="textarea"
        :placeholder="placeholder"
        :value="value"
        @input="onInput"
        @focus="onFocus"
        @keyup="onKeyup"
        @blur="onBlur"
        class="form-input user-message-input is-focused"
        :disabled="hasSubmittedValues && contentType !== 'input_csat'"
    />
  </div>

</template>

<script>
import ChatOptions from "./ChatOptions.vue";
import {mapActions, mapGetters} from "vuex";
import WootAudioRecorder from "../../dashboard/components/widgets/WootWriter/AudioRecorder.vue";
import WootButton from "../../dashboard/components/ui/WootButton.vue";
import alertMixin from '../../shared/mixins/alertMixin'


const TYPING_INDICATOR_IDLE_TIME = 4000;
export default {
  mixins: [alertMixin],
  components: {WootButton, WootAudioRecorder, ChatOptions},
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    minHeight: {
      type: Number,
      default: 2,
    },
    shouldDisableForPhone: {
      type: Boolean,
      default: false,
    },
    shouldDisableForOptions: {
      type: Boolean,
      default: false,
    },
    messageId: {type: Number, default: null},
    messageContentAttributes: {
      type: Object,
      default: () => {
      },
    },
    hasSubmittedValues: {
      type: Boolean,
      default: false,
    },
    shouldDisableNumbers: {
      type: Boolean,
      default: false,
    },
    shouldDisableDuration: {
      type: Boolean,
      default: false
    },
    shouldDisableDate: {
      type: Boolean,
      default: false
    },
    shouldDisableOtp: {
      type: Boolean,
      default: false
    },
    shouldDisableOtpFDigits: {
      type: Boolean,
      default: false
    },
    contentType: {
      type: String,
      default: '',
    },
    ShouldDisablePrice: {
      type: Boolean,
      default: false
    },
    shouldShowWarning: {
      type: Boolean,
      default: false
    },
    isRecording: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      idleTimer: null,
      showWarning: false,// Add a flag to control the warning display
      initialDate: '',
      productsIDs: [], selectedProducts: [],
      priceTotal: 0,
      selectedYear: '',
      selectedMonth: '',
      months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      mediaRecorder: null,
      audioChunks: [],
      recordingTime: 0,
      timerInterval: null,
      maxRecordingTime: 60,// Set a maximum recording time if needed
      isCanceling: false, // Add this flag
      showAttachmentRecording: false, // or true based on your logic
      isRecordingAudio: false,
      showAudio: true,
      recordingAudioState: '',
      recordingAudioDurationText: '',
      recordedAudioFile: null,
    };
  },
  watch: {
    value() {
       this.resizeTextarea();
    },
  },
  computed: {
    ...mapGetters({
      productSize: 'appConfig/productSize',
      widgetColor: 'appConfig/getWidgetColor',
    }),

    yearPlaceholder() {
      return `Année (1 à ${this.maxYearValue})`;
    },
    maxYearValue() {
      const items = this.messageContentAttributes.items || [];
      if (items.length === 0) return 14; // Default to 14 if no items

      const maxYear = Math.max(...items.map(item => parseInt(item.value, 10)));
      return maxYear > 1 ? Math.min(maxYear - 1, 14) : 1;
    },
    years() {
      return Array.from({length: this.maxYearValue}, (_, i) => i + 1);
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.value) {
        this.resizeTextarea();
      }
    });
    this.initialDate = this.getTodayDate();

  },
  created() {
    this.$on('reset-selection', this.resetSelection);
  },


  methods: {
    resizeTextarea() {
      const textarea = this.$refs.textarea;
      if (!this.value) {
        textarea.style.height = `${this.minHeight}rem`;
      } else {
        textarea.style.height = `${this.minHeight}rem`;
        textarea.style.height = `${textarea.scrollHeight}px`;
      }
    },
    ...mapActions('appConfig', [
      'setAppConfig',
      'setReferrerHost',
      'setWidgetColor',
      'setProductSize',
    ]),
    resetSelection() {
      this.selectedYear = '';
      this.selectedMonth = '';
    },
    formatMediaUrl(mediaUrl) {
      const {carrefour_url} = window.convergenceWebChannel;
      const {carrefour_image_static_url} = window.convergenceWebChannel;
      const {carrefour_image_url} = window.convergenceWebChannel;
      if (mediaUrl.startsWith('productsimages/')) {
        return carrefour_image_static_url + mediaUrl;
      } else if (mediaUrl.startsWith('media/catalog/product/cache/')) {
        return carrefour_url + mediaUrl;
      } else {
        return carrefour_url + carrefour_image_url + mediaUrl;
      }
    },

    getWarningMessage() {
      if (this.shouldDisableNumbers) {
        return "La valeur doit être égale à 8 chiffres.";
      } else if (this.shouldDisableOtp) {
        return "La valeur doit être égale à 4 chiffres.";
      } else if (this.shouldDisableOtpFDigits) {
        return "La valeur doit être égale à 5 chiffres.";
      } else {
        return "";
      }
    },

    onYearChange(event) {
      this.selectedYear = event.target.value;
      this.emitFormattedDuration();
    },
    onMonthChange(event) {
      this.selectedMonth = event.target.value;
      this.emitFormattedDuration();
    },
    emitFormattedDuration() {
      let yearValue = parseInt(this.selectedYear) || 0;
      const monthValue = parseInt(this.selectedMonth) || 0;

      let formattedDuration = '';
      if (monthValue === 12) {// Use parseInt to ensure comparison is correct
        yearValue += 1;
        formattedDuration = `${yearValue} ${yearValue > 1 ? 'ans' : 'an'}`;
      } else {
        const yearText = yearValue ? `${yearValue} ${yearValue > 1 ? 'ans' : 'an'}` : '';
        const monthText = monthValue ? `${monthValue} ${monthValue > 1 ? 'mois' : 'mois'}` : '';
        if (yearText && monthText) {
          formattedDuration = `${yearText}   ${monthText}`;
        } else if (yearText) {
          formattedDuration = yearText;
        } else if (monthText) {
          formattedDuration = monthText;
        }
      }

      this.$emit('input', formattedDuration);
    },

    onInput(event) {
      if (this.shouldDisableForPhone && !this.shouldDisableForOptions && !this.hasSubmittedValues) {
        let inputValue = event.target.value;
        inputValue = inputValue.replace(/\D/g, '');
        event.target.value = inputValue;
        this.$emit('input', inputValue);
        this.showWarning = inputValue.length > 8;
      }
      if (this.shouldDisableNumbers || this.shouldDisableOtp || this.shouldDisableOtpFDigits) {
        let inputValue = event.target.value;
        inputValue = inputValue.replace(/\D/g, '');
        event.target.value = inputValue;
        this.$emit('input', inputValue);
        this.showWarning = (this.shouldDisableOtp && inputValue.length > 4) || (this.shouldDisableNumbers && inputValue.length > 8) || (this.shouldDisableOtpFDigits && inputValue.length > 5);

      }
      if (this.ShouldDisablePrice) {
        let inputValue = event.target.value;
        const floatRegex = /^\d*(\.\d{0,4})?$/;
        if (!floatRegex.test(inputValue)) {
          const validMatch = inputValue.match(/\d*(\.\d{0,4})?/);
          inputValue = validMatch ? validMatch[0] : '';
        }
        this.userInput = inputValue;
        event.target.value = inputValue;
        this.$emit('input', event.target.value);
      } else {
        this.$emit('input', event.target.value);
        this.resizeTextarea();
      }
    },


    onDateChange(event) {
      const selectedDate = event.target.value;
      this.$emit('date-selected', selectedDate);
    },

    resetTyping() {
      this.$emit('typing-off');
      this.idleTimer = null;
    },


    turnOffIdleTimer() {
      if (this.idleTimer) {
        clearTimeout(this.idleTimer);
      }
    },


    onKeyup() {
      if (!this.idleTimer) {
        this.$emit('typing-on');
      }
      this.turnOffIdleTimer();
      this.idleTimer = setTimeout(
          () => this.resetTyping(),
          TYPING_INDICATOR_IDLE_TIME
      );
    },

    onBlur() {
      this.turnOffIdleTimer();
      this.resetTyping();
      this.$emit('blur');
    },

    onFocus() {
      if (!this.shouldDisableDate && !this.shouldDisableDuration) {
        this.$emit('focus');
      }
    },

    focus() {
      this.$refs.textarea.focus();
    },

    onResponse(messageResponse) {
      if (messageResponse.ignoreClicked) {
        messageResponse.submittedValues = [{value: 'ignorer'}];
      }
      this.$store.dispatch('message/update', messageResponse);
    },

    onOptionSelect(selectedOption) {
      if (this.contentType === 'cards_products') {
        const submittedValues = Array.isArray(selectedOption)
            ? selectedOption.map(option => option.productItem)
            : [selectedOption.productItem];
        this.onResponse({
          ignoreClicked: false,
          submittedValues: submittedValues,
          messageId: this.messageId,
        });
      } else {
        this.onResponse({
          submittedValues: [selectedOption],
          messageId: this.messageId,
        });
      }
    },
    getTodayDate() {
      const now = new Date();
      const year = now.getFullYear();
      let month = (now.getMonth() + 1).toString().padStart(2, '0');
      let day = now.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    onFinishRecorder(file) {
      this.recordedAudioFile = file;
    },
    onStateProgressRecorderChanged(duration) {
      this.recordingAudioDurationText = duration;
    },
    onStateRecorderChanged(state) {
      this.recordingAudioState = state;
      if (this.recordingAudioState === "recording" || this.recordingAudioState === "stopped") {
        this.showAudio = true;
      }
      if (this.recordingAudioState === "recording") {
        this.showAudio = true;
        this.recordedAudioFile = null;
      }
      if (state && 'notallowederror'.includes(state)) {
        this.toggleAudioRecorder();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.flag-icon {
  width: 30px;
  height: auto;
}

@import '~widget/assets/scss/variables.scss';
@import '~widget/assets/scss/mixins.scss';

.chat-message--input {
  align-items: center;
  display: flex;
  padding: 10px 0.5rem 20px 0.75rem;
  border-radius: 7px;

}

.emoji-dialog {
  right: 0;
  top: -302px;
  max-width: 100%;

  &::before {
    right: $space-one;
  }
}

.button-wrap {
  display: flex;
  align-items: center;
  padding-left: $space-small;
}

.user-message-input {
  border: 0;
  height: $space-large;
  min-height: $space-large;
  max-height: 2.4 * $space-mega;
  resize: none;
  padding: $space-smaller 0;
  margin-top: $space-small;
  margin-bottom: $space-small;
}

.plus-code {
  margin-left: 10px;
  margin-right: 10px; /* Adjust the margin as needed */
}

.centered {
  display: flex;
  justify-content: center;
}

.notification-warning {
  position: absolute; /* Position the warning absolutely */
  top: -30px; /* Adjust the top position */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Center horizontally */
  background-color: #ffcccc; /* Set the background color */
  color: #cc0000; /* Set the text color */
  padding: 10px; /* Add padding */
  border-radius: 5px; /* Add border radius */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Add box shadow */
}

.warning-icon {
  width: 24px; /* Adjust icon size as needed */
  height: auto;
  margin-right: 10px;
  fill: currentColor; /* Set the SVG icon color to current text color */
}

.warning-box {
  position: absolute;
  top: -70px; /* Adjust position as needed */
  left: 0; /* Center horizontally */
  transform: translateX(30%); /* Center horizontally */
  width: 300px;
  padding: 10px;
  background-color: #ffffff; /* White background color */
  color: #111111; /* Black text color */
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5); /* Shadow */
}

.warning-box.numbers {
  position: absolute;
  top: -70px; /* Adjust position as needed */
  left: 0; /* Center horizontally */
  transform: translateX(0%); /* Center horizontally */
  width: 300px;
  padding: 10px;
  background-color: #ffffff; /* White background color */
  color: #111111; /* Black text color */
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5); /* Shadow */
}

.input-container {
  position: relative;
}


</style>
