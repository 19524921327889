<template>
    <div class="medium-2 small-6 csat--metric-card">
        <h3 class="heading">
            <span>{{ label }}</span>
            <fluent-icon
                    v-tooltip="infoText"
                    size="14"
                    icon="info"
                    class="csat--icon"
            />
        </h3>
        <h4 class="metric" :class="{'margin-satisfact': isSatisfaction}">
            {{ value }}
        </h4>
    </div>
</template>
<script>
export default {
    props: {
        label: {
            type: String,
            required: true,
        },
        value: {
            type: String,
            required: true,
        },
        infoText: {
            type: String,
            required: true,
        } ,
        isSatisfaction: {
            type: Boolean,
            default: false,
        }
        /*disabled: {
          type: Boolean,
          default: false,
        },*/
    },
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 1304px) {
  .margin-satisfact{
    margin-top: 2.3rem !important;
  }
}

@media screen and (min-width: 1304px) {
  .margin-satisfact{
    margin-top: 0.4rem !important;
  }
}
.csat--metric-card {
  margin: 0;
  padding: var(--space-normal) var(--space-small) var(--space-normal)
  var(--space-two);

  &.disabled {
    // grayscale everything
    filter: grayscale(100%);
    opacity: 0.3;
    pointer-events: none;
  }

  .heading {
    align-items: center;
    color: var(--color-heading);
    display: flex;
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-bold);
    margin: 0;
  }

  .metric {
    font-size: var(--font-size-bigger);
    font-weight: var(--font-weight-feather);
    margin-bottom: 0;
    margin-top: var(--space-smaller);
  }
}

.csat--icon {
  color: var(--b-400);
  margin-left: var(--space-micro);
}
</style>
