<template>
  <div class="cw-accordion">
    <button class="cw-accordion--title drag-handle" @click="$emit('click')">
      <div class="cw-accordion--title-wrap">
        <emoji-or-icon class="icon-or-emoji" :icon="icon" :emoji="emoji" />
        <h5>
          {{ title }}
        </h5>
      </div>
      <div class="button-icon--wrap">
        <slot name="button" />
        <div class="chevron-icon__wrap">
            <svg v-if="isOpen" width="14" height="7" viewBox="0 0 14 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.451 6.52469C13.202 6.77365 12.7984 6.77365 12.5494 6.52469L7.65941 1.63469C7.33087 1.30615 6.78952 1.30615 6.46098 1.63469L1.57098 6.52469C1.32202 6.77365 0.918373 6.77365 0.669414 6.52469C0.420453 6.27573 0.420453 5.87208 0.669414 5.62312L5.55941 0.733125C6.38587 -0.0933343 7.73452 -0.0933341 8.56098 0.733125L13.451 5.62313C13.6999 5.87209 13.6999 6.27573 13.451 6.52469Z" fill="#483A9D"/>
            </svg>
            <!--            <fluent-icon v-if="isOpen" size="24" icon="subtract" type="solid" />-->
            <svg v-else width="14" height="7" viewBox="0 0 14 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.549024 0.475312C0.797983 0.226353 1.20163 0.226353 1.45059 0.475312L6.34059 5.36531C6.66913 5.69385 7.21048 5.69385 7.53902 5.36531L12.429 0.475312C12.678 0.226353 13.0816 0.226353 13.3306 0.475312C13.5795 0.724272 13.5795 1.12791 13.3306 1.37687L8.44059 6.26687C7.61413 7.09333 6.26548 7.09333 5.43902 6.26687L0.549024 1.37687C0.300065 1.12791 0.300065 0.724272 0.549024 0.475312Z" fill="#483A9D"/>
            </svg>
            <!--            <fluent-icon v-else size="24" icon="add" type="solid" />-->
        </div>
      </div>
    </button>
    <div
      v-if="isOpen"
      class="cw-accordion--content"
      :class="{ compact: compact }"
    >
        <svg class="ml-3 sepatation-line" width="280" height="1" viewBox="0 0 300 1" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="0.25" y1="0.75" x2="299.75" y2="0.75" stroke="#A49DCF" stroke-width="0.5" stroke-linecap="round"/>
        </svg>
      <slot />
    </div>
  </div>
</template>

<script>
import EmojiOrIcon from 'shared/components/EmojiOrIcon';

export default {
  components: {
    EmojiOrIcon,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    compact: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    emoji: {
      type: String,
      default: '',
    },
    isOpen: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.cw-accordion {
  // This is done to fix contact sidebar border issues
  // If you are using it else, find a fix to remove this hack
  margin-top: -1px;
  font-size: var(--font-size-small);
}
.cw-accordion--title {
  align-items: center;
  background: #F9F9F9;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  //background: var(--s-50);
  //border-bottom: 1px solid var(--s-100);
  //border-top: 1px solid var(--s-100);
  cursor: grab;
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: var(--space-small) var(--space-normal);
  user-select: none;
  width: 100%;

  h5 {
    //font-size: var(--font-size-normal);
    margin-bottom: 0;
    padding: 0 var(--space-small) 0 0;
    color: #0081c9 !important; /* 483A9D */
    font-family: 'Inter' !important;;
    font-style: normal !important;;
    font-weight: 500 !important;;
    font-size: 14px !important;;
    line-height: 19px !important;;
  }
}

.cw-accordion--title-wrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--space-micro);
}

.title-icon__wrap {
  display: flex;
  align-items: baseline;
}

.icon-or-emoji {
  display: inline-block;
  width: var(--space-two);
}

.button-icon--wrap {
  display: flex;
  flex-direction: row;
}

.chevron-icon__wrap {
  display: flex;
  justify-content: flex-end;
  width: var(--space-slab);
  color: var(--w-500);
}

.cw-accordion--content {
  padding-top: 0 !important;
  padding: var(--space-normal);
  background: #F9F9F9 !important;
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  display: flex !important;
  flex-direction: column;
  justify-content: space-between !important;
  &.compact {
    padding: 0;
  }
}
.sepatation-line{
  margin-bottom: 1rem !important;
  margin-left: 0rem !important;
  width: 100% !important;
}
</style>
