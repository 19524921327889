<template>
    <form @submit.prevent="changePassword()">
        <div class="small-12 columns">
            <div>
                <div class="d-flex">
                    <div class="small-3 columns">
                        <label  class="right inline profile_labels">
                            {{ $t('PROFILE_SETTINGS.FORM.CURRENT_PASSWORD.LABEL')}} :
                        </label>
                    </div>
                    <div class="small-9 columns ml-5">
                        <input
                                v-model="currentPassword"
                                type="password"
                                :placeholder="$t('PROFILE_SETTINGS.FORM.CURRENT_PASSWORD.PLACEHOLDER')"
                                class="profile_inputs"
                        />
                    </div>
                </div>

                <div class="d-flex">
                    <div class="small-3 columns">
                        <label class="right inline profile_labels">
                            {{ $t('PROFILE_SETTINGS.FORM.PASSWORD.LABEL')}} :
                        </label>
                    </div>
                    <div class="small-9 columns ml-5">
                        <input
                                v-model="password"
                                type="password"
                                :placeholder="$t('PROFILE_SETTINGS.FORM.PASSWORD.PLACEHOLDER')"
                                class="profile_inputs"
                        />
                    </div>
                </div>

                <div class="d-flex">
                    <div class="small-3 columns">
                        <label class="right inline profile_labels">
                            {{ $t('PROFILE_SETTINGS.FORM.PASSWORD_CONFIRMATION.LABEL')}} :
                        </label>
                    </div>
                    <div class="small-9 columns ml-5">
                        <input
                                v-model="passwordConfirmation"
                                type="password"
                                :placeholder=" $t('PROFILE_SETTINGS.FORM.PASSWORD_CONFIRMATION.PLACEHOLDER')"
                                class="profile_inputs"
                        />
                        <div class="d-flex mt-2 ps_zone mb-5">
                            <svg width="14" height="18" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.0204 10.33L12.8004 9.11C12.5104 8.86 12.3404 8.49 12.3304 8.08C12.3104 7.63 12.4904 7.18 12.8204 6.85L14.0204 5.65C15.0604 4.61 15.4504 3.61 15.1204 2.82C14.8004 2.04 13.8104 1.61 12.3504 1.61H1.90039V0.75C1.90039 0.34 1.56039 0 1.15039 0C0.740391 0 0.400391 0.34 0.400391 0.75V19.25C0.400391 19.66 0.740391 20 1.15039 20C1.56039 20 1.90039 19.66 1.90039 19.25V14.37H12.3504C13.7904 14.37 14.7604 13.93 15.0904 13.14C15.4204 12.35 15.0404 11.36 14.0204 10.33Z" fill="#292D32"/>
                            </svg>
                            <h4 class="text-gray-400 custom_txt ml-3">{{ $t('PROFILE_SETTINGS.FORM.PASSWORD_CONFIRMATION.DESCRIPTION')}}</h4>
                        </div>
                    </div>
                </div>


                <div class="float-right mt-3 mleft_5">
                    <woot-button
                            :is-loading="isPasswordChanging"
                            type="submit"
                            :disabled="
                            !currentPassword ||
                              !passwordConfirmation ||
                              !$v.passwordConfirmation.isEqPassword
                          "
                    >
                        {{ $t('PROFILE_SETTINGS.FORM.PASSWORD_SECTION.BTN_TEXT') }}
                    </woot-button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import WootButton from "../../../../components/ui/WootButton";

export default {
    components: {WootButton},
    mixins: [alertMixin],
    data() {
        return {
            currentPassword: '',
            password: '',
            passwordConfirmation: '',
            isPasswordChanging: false,
            errorMessage: '',
        };
    },
    validations: {
        currentPassword: {
            required,
        },
        password: {
            minLength: minLength(6),
        },
        passwordConfirmation: {
            minLength: minLength(6),
            isEqPassword(value) {
                if (value !== this.password) {
                    return false;
                }
                return true;
            },
        },
    },
    computed: {
        ...mapGetters({
            currentUser: 'getCurrentUser',
            currentUserId: 'getCurrentUserID',
        }),
    },
    methods: {
        async changePassword() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.showAlert(this.$t('PROFILE_SETTINGS.FORM.ERROR'));
                return;
            }

            try {
                await this.$store.dispatch('updateProfile', {
                    password: this.password,
                    password_confirmation: this.passwordConfirmation,
                    current_password: this.currentPassword,
                });
                this.errorMessage = this.$t('PROFILE_SETTINGS.PASSWORD_UPDATE_SUCCESS');
            } catch (error) {
                this.errorMessage = this.$t('RESET_PASSWORD.API.ERROR_MESSAGE');
                if (error?.response?.data?.message) {
                    this.errorMessage = error.response.data.message;
                }
            } finally {
                this.isPasswordChanging = false;
                this.showAlert(this.errorMessage);
            }
        },
    },
};
</script>

<style lang="scss">
@import '~dashboard/assets/scss/mixins.scss';

.profile--settings--row {
  //@include border-normal-bottom;
  padding: var(--space-normal);
  .small-3 {
    padding: var(--space-normal) var(--space-medium) var(--space-normal) 0;
  }
  .small-9 {
    padding: var(--space-normal);
  }
}

.custom_txt{
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  color: #737373 !important;
  margin-top: 2px !important;
}


.mleft_5{
  margin-right: -4rem!important;
  margin-top: 5rem!important;
}


.ps_zone{
  background: #F9F9F9 !important;
  border-radius: 5px !important;
  padding: 10px !important;
}

</style>
