<template>
  <div
    class="column content-box"
    style="padding: 0 13rem; background-color: white;"
  >
    <woot-modal-header
      :header-title="$t('CAMPAIGN.ADD.TITLE')"
      :header-content="$t('CAMPAIGN.ADD.DESC')"
      class="modal_header"
    />
    <form class="row" @submit.prevent="addCampaign">
      <div class="columns">
        <div class="mb-2 mt-2">
            <input
                    v-model="title"
                    type="text"
                    :placeholder="$t('CAMPAIGN.ADD.FORM.TITLE.PLACEHOLDER')"
                    @input="$v.title.$touch"
                    class="custom_input"
                    maxlength="50"
            />
            <!-- title's error message  -->
            <span v-if="$v.title.$error" class="message">
              {{ $t('CAMPAIGN.ADD.FORM.TITLE.ERROR') }}
          </span>
        </div>

        <div class="mb-2 mt-3" v-if="isOngoingType">
          <woot-message-editor
            v-model="message"
            class="message-editor custom_txtarea"
            :class="{ editor_warning: $v.message.$error }"
            :placeholder="$t('CAMPAIGN.ADD.FORM.MESSAGE.PLACEHOLDER')"
            @blur="$v.message.$touch"
          />
            <!-- message's error message  -->
          <span v-if="$v.message.$error" class="message" style="margin-top: 0px">
            {{ $t('CAMPAIGN.ADD.FORM.MESSAGE.ERROR') }}
          </span>
        </div>

        <div class="mb-2 mt-3" v-else>
          <textarea
            v-model="message"
            rows="5"
            type="text"
            :placeholder="$t('CAMPAIGN.ADD.FORM.MESSAGE.PLACEHOLDER')"
            @blur="$v.message.$touch"
            class="custom_txtarea"
          />
          <span v-if="$v.message.$error" class="message">
            {{ $t('CAMPAIGN.ADD.FORM.MESSAGE.ERROR') }}
          </span>
        </div>
        <div class="mb-2 mt-3">
          {{ $t('CAMPAIGN.ADD.FORM.INBOX.LABEL') }}
            <!-- selectedInbox's style  -->
            <inbox-drop-down
                    :options="inboxes"
                    :selected-item="selectedInbox"
                    :from-report="true"
                    :is-on-off="isOnOffType"
                    :multiselector-title="$t('NEW_CONVERSATION.FORM.INBOX.SELECT_INBOX')"
                    :no-search-result="
                      $t('NEW_CONVERSATION.FORM.INBOX.SEARCH.NO_RESULTS')
                    "
                    :input-placeholder="
                      $t('NEW_CONVERSATION.FORM.INBOX.SEARCH.PLACEHOLDER')
                    "
                    :new-campaign="true"
                    :not-selected-inbox="notSelectedInbox"
                    :is-date-open="isDateOpen"
                    @click="onChangeInbox"
            />
          <span v-if="$v.selectedInbox.$error || notSelectedInbox" class="message" style="margin-top: -0.5rem;">
            {{ $t('CAMPAIGN.ADD.FORM.INBOX.ERROR') }}
          </span>
        </div>

        <div class="mb-2 mt-3" v-if="isOnOffType">
          <multiselect
            v-model="selectedAudience"
            :options="audienceList"
            track-by="id"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :hide-selected="true"
            :placeholder="$t('CAMPAIGN.ADD.FORM.AUDIENCE.PLACEHOLDER')"
            selected-label
            @blur="$v.selectedAudience.$touch"
            @select="$v.selectedAudience.$touch"
          />
            <!-- selectedAudience's error message  -->
          <span v-if="$v.selectedAudience.$error" class="message">
            {{ $t('CAMPAIGN.ADD.FORM.AUDIENCE.ERROR') }}
          </span>
        </div>

        <div class="mb-2 mt-3" v-if="isOngoingType">
            <inbox-drop-down
                    :options="sendersAndBotList"
                    :selected-item="selectedSender"
                    :from-report="true"
                    :multiselector-title="$t('CAMPAIGN.ADD.FORM.SENT_BY.PLACEHOLDER')"
                    :no-search-result="
                      $t('CAMPAIGN.ADD.FORM.SENT_BY.SEARCH.NO_RESULTS')
                    "
                    :input-placeholder="
                      $t('NEW_CONVERSATION.FORM.INBOX.SEARCH.PLACEHOLDER')
                    "
                    @click="onChangeSender"
            />
          <span v-if="$v.selectedSender.$error" class="message">
            {{ $t('CAMPAIGN.ADD.FORM.SENT_BY.ERROR') }}
          </span>
        </div>

        <div id="form-date" class="mb-2 mt-3" v-if="isOnOffType">
          <woot-date-time-picker
            :value="scheduledAt"
            :confirm-text="$t('CAMPAIGN.ADD.FORM.SCHEDULED_AT.CONFIRM')"
            :placeholder="$t('CAMPAIGN.ADD.FORM.SCHEDULED_AT.PLACEHOLDER')"
            @open="toggleOpenDate"
            @close="toggleCloseDate"
            @change="onChange"
          />
        </div>

        <div class="mb-2 mt-3" v-if="isOngoingType">
          <input
            :class="{ error: $v.endPoint.$error }"
            v-model="endPoint"
            type="text"
            value="enabled"
            :placeholder="$t('CAMPAIGN.ADD.FORM.END_POINT.PLACEHOLDER')"
            @input="$v.endPoint.$touch"
            class="w-100  custom_input"
          />
          <span
            v-if="$v.endPoint.$error"
            class="message"
          >
            {{ $t('CAMPAIGN.ADD.FORM.END_POINT.ERROR') }}
          </span>
        </div>

        <div class="mb-2 mt-1" v-if="isOngoingType">
          <input
            v-if="isOngoingType"
            v-model="timeOnPage"
            type="text"
            :class="{ error: $v.timeOnPage.$error }"
            :placeholder="$t('CAMPAIGN.ADD.FORM.TIME_ON_PAGE.PLACEHOLDER')"
            @input="$v.timeOnPage.$touch"
            class="w-100 custom_input"
          />
           <!-- timeOnPage's error message  -->
           <span v-if="$v.timeOnPage.$error" class="message">
              {{ $t('CAMPAIGN.ADD.FORM.TIME_ON_PAGE.ERROR') }}
          </span>
        </div>

        <div class="mb-2 mt-3 checkbox_style" v-if="isOngoingType">
          <input
            v-model="enabled"
            type="checkbox"
            value="enabled"
            name="enabled"
          />
          {{ $t('CAMPAIGN.ADD.FORM.ENABLED') }}
        </div>

        <div class="mb-2 mt-2 checkbox_style" v-if="isOngoingType">
          <input
            v-model="triggerOnlyDuringBusinessHours"
            type="checkbox"
            value="triggerOnlyDuringBusinessHours"
            name="triggerOnlyDuringBusinessHours"
          />
          {{ $t('CAMPAIGN.ADD.FORM.TRIGGER_ONLY_BUSINESS_HOURS') }}
        </div>

        <div class="d-flex float-right mt-3" style="direction: rtl; margin-bottom: -2rem ;">
          <woot-button :is-loading="uiFlags.isCreating">
            {{ $t('CAMPAIGN.ADD.CREATE_BUTTON_TEXT') }}
          </woot-button>
          <woot-button class="ml-2" variant="clear" @click.prevent="onClose">
            {{ $t('CAMPAIGN.ADD.CANCEL_BUTTON_TEXT') }}
          </woot-button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import alertMixin from 'shared/mixins/alertMixin';
import WootMessageEditor from 'dashboard/components/widgets/WootWriter/Editor';
import campaignMixin from 'shared/mixins/campaignMixin';
import WootDateTimePicker from 'dashboard/components/ui/DateTimePicker.vue';
import { URLPattern } from 'urlpattern-polyfill';
import { CAMPAIGNS_EVENTS } from '../../../../helper/AnalyticsHelper/events';
import InboxDropDown from '../../conversation/contact/InboxDropDown.vue';

export default {
  components: {
    WootDateTimePicker,
    WootMessageEditor,
    InboxDropDown,
  },

  mixins: [alertMixin, campaignMixin],
  data() {
    return {
      title: '',
      message: '',
      selectedSender: {
          id: 0,
          name: 'Bot',
      },
      selectedInbox: {
          id: '',
          name: this.$t('NEW_CONVERSATION.FORM.INBOX.SELECT_INBOX'),
      },
      endPoint: '',
      timeOnPage: 10,
      show: true,
      enabled: true,
      triggerOnlyDuringBusinessHours: false,
      scheduledAt: null,
      selectedAudience: [],
      senderList: [],
      notSelectedInbox: false,
      isDateOpen: false,
    };
  },

  validations() {
    const commonValidations = {
      title: {
        required,
      },
      message: {
        required,
      },
      selectedInbox: {
        required,
      },
    };
    if (this.isOngoingType) {
      return {
        ...commonValidations,
        selectedSender: {
          required,
        },
        endPoint: {
          required,
          shouldBeAValidURLPattern(value) {
            try {
              // eslint-disable-next-line
              new URLPattern(value);
              return true;
            } catch (error) {
              return false;
            }
          },
          shouldStartWithHTTP(value) {
            if (value) {
              return (
                value.startsWith('https://') || value.startsWith('http://')
              );
            }
            return false;
          },
        },
        timeOnPage: {
          required,
        },
      };
    }
    return {
      ...commonValidations,
      selectedAudience: {
        isEmpty() {
          return !!this.selectedAudience.length;
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'campaigns/getUIFlags',
      audienceList: 'labels/getLabels',
    }),
    inboxes() {
      if (this.isOngoingType) {
        return this.$store.getters['inboxes/getWebsiteInboxes'];
      }
      return this.$store.getters['inboxes/getSMSInboxes'];
    },
    sendersAndBotList() {
      return [
        {
          id: 0,
          name: 'Bot',
        },
        ...this.senderList,
      ];
    },
  },
  mounted() {
    this.$track(CAMPAIGNS_EVENTS.OPEN_NEW_CAMPAIGN_MODAL, {
      type: this.campaignType,
    });
  },
  methods: {
    onClose() {
      this.$emit('on-close');
    },
    onChange(value) {
      this.scheduledAt = value;
    },
    async onChangeInbox(option) {
      try {
        if (this.selectedInbox && this.selectedInbox.id === option.id) {
            this.selectedInbox = {
                  id: '',
                  name: this.$t('NEW_CONVERSATION.FORM.INBOX.SELECT_INBOX'),
            };
            this.notSelectedInbox = true;
        }
        else {
            this.selectedInbox = option;
            this.notSelectedInbox = false;
            const response = await this.$store.dispatch('inboxMembers/get', {
                inboxId: this.selectedInbox.id,
            });
            const {
                data: { payload: inboxMembers },
            } = response;
            this.senderList = inboxMembers;
        }
      } catch (error) {
        const errorMessage =
          error?.response?.message || this.$t('CAMPAIGN.ADD.API.ERROR_MESSAGE');
        this.showAlert(errorMessage);
      }
    },
    onChangeSender(option) {
        if (this.selectedSender && this.selectedSender.id === option.id) {
            this.selectedSender = {
                id: 0,
                name: 'Bot',
            };
        }
        else {
            this.selectedSender = option;
        }
    },
    getCampaignDetails() {
      let campaignDetails = null;
      if (this.isOngoingType) {
        campaignDetails = {
          title: this.title,
          message: this.message,
          inbox_id: this.selectedInbox.id,
          sender_id: this.selectedSender.id || null,
          enabled: this.enabled,
          trigger_only_during_business_hours:
            // eslint-disable-next-line prettier/prettier
            this.triggerOnlyDuringBusinessHours,
          trigger_rules: {
            url: this.endPoint,
            time_on_page: this.timeOnPage,
          },
        };
      } else {
        const audience = this.selectedAudience.map(item => {
          return {
            id: item.id,
            type: 'Label',
          };
        });
        campaignDetails = {
          title: this.title,
          message: this.message,
          inbox_id: this.selectedInbox,
          scheduled_at: this.scheduledAt,
          audience,
        };
      }
      return campaignDetails;
    },
    async addCampaign() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        if (this.selectedInbox.id ==='') {
           this.notSelectedInbox = true;
        }
        return;
      }
      else if (this.selectedInbox.id ==='') {
          this.notSelectedInbox = true;
      }
      else {
          try {
              const campaignDetails = this.getCampaignDetails();
              await this.$store.dispatch('campaigns/create', campaignDetails);

              // tracking this here instead of the store to track the type of campaign
              this.$track(CAMPAIGNS_EVENTS.CREATE_CAMPAIGN, {
                  type: this.campaignType,
              });

              this.showAlert(this.$t('CAMPAIGN.ADD.API.SUCCESS_MESSAGE'));
              this.onClose();
          } catch (error) {
              const errorMessage =
                  error?.response?.message || this.$t('CAMPAIGN.ADD.API.ERROR_MESSAGE');
              this.showAlert(errorMessage);
          }
      }
    },
    toggleOpenDate(value) {
      this.isDateOpen = value;
    },
    toggleCloseDate(value) {
      this.isDateOpen = value;
    }
  },
};
</script>
<style lang="scss" scoped>
::v-deep .ProseMirror-woot-style {
  height: 8rem;
}

.modal_header {
  margin-top: 3rem !important;
  text-align: center;
}

.custom_input {
  border: 1px solid #737373;
  border-radius: 8px;
  height: 55px;
}

.custom_txtarea {
  border: 1px solid #737373;
  border-radius: 8px;
}

.error_msg {
  color: red;
  margin-top: -2px;
  margin-bottom: 2px;
}

.checkbox_style {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #656877;
}

::v-deep .multiselect{
  .multiselect__tags{
    .multiselect__tags-wrap{
      display: grid !important;
    }
  }
}

/* error message's style */
.message {
  color: #f94b4a;
  color: var(--r-400);
  display: block;
  font-size: 1.4rem;
  font-size: var(--font-size-small);
  font-weight: 400;
  margin-bottom: 1rem;
  margin-top: -1.6rem;
  width: 100%;
}
</style>
