<template>
    <woot-modal :show.sync="show" :on-close="onCancel">
        <div class="column content-box">
            <woot-modal-header
                    :header-title="$t('CREATE_CONTACT.TITLE')"
                    :header-content="$t('CREATE_CONTACT.DESC')"
            />
            <contact-form
                    :in-progress="uiFlags.isCreating"
                    :on-submit="onSubmit"
                    @success="onSuccess"
                    @cancel="onCancel"
            />
        </div>
    </woot-modal>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';
import ContactForm from './ContactForm';

export default {
    components: {
        ContactForm,
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        contact: {
            type: Object,
            default: () => ({}),
        },
    },

    computed: {
        ...mapGetters({
            uiFlags: 'contacts/getUIFlags',
        }),
    },

    methods: {
        ...mapMutations(["updateSearchQuery"]),
        onCancel() {
            this.$emit('cancel');
        },
        onSuccess() {
            this.$emit('success');
        },
        async onSubmit(contactItem) {
            await this.$store.dispatch('contacts/create', contactItem);
            this.updateSearchQuery('') ; /* to clear search field after create action  */
            this.$emit('create');
        },
    },
};
</script>
