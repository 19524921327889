<template>
    <div class="column">
        <woot-modal-header :header-title="$t('COMPLAINT_SIDEBAR.COMPLAINT_FILTER.TITLE')" />
        <div class="column modal-content">
            <div class="medium-12 columns filters-wrap">
                <div class="filters">
                    <div class="filter-inputs">
                        <multiselect-dropdown
                                :options="claimsReference"
                                :selected-item="currentSelectedRef"
                                :multiselector-title="$t('COMPLAINT_SIDEBAR.COMPLAINT_FILTER.TITLE_FILTER')"
                                :multiselector-placeholder="$t('COMPLAINT_SIDEBAR.COMPLAINT_FILTER.ALL_REF')"
                                :has-thumbnail="false"
                                :is-claim="true"
                                @click="changeFilterRef"
                        />
                        <woot-button
                                icon="dismiss"
                                variant="clear"
                                color-scheme="secondary"
                                @click="removeFilter"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="medium-12 columns">
            <div class="modal-footer justify-content-end w-full">
                <woot-button class="button clear" @click.prevent="onClose">
                    {{ $t('COMPLAINT_SIDEBAR.COMPLAINT_FILTER.CANCEL') }}
                </woot-button>
                <woot-button style="margin-right: 1rem" @click="submitFilterRef">
                    {{ $t('COMPLAINT_SIDEBAR.COMPLAINT_FILTER.APPLY') }}
                </woot-button>
            </div>
        </div>
    </div>
</template>

<script>
import MultiselectDropdown from 'shared/components/ui/MultiselectDropdown.vue' ;
export default {
    components: {
        MultiselectDropdown,
    },
    props: {
        claimsReference: {
            type: Array,
            default: () => [] ,
        },
        onClose: {
            type: Function,
            default: () => {},
        },
        initialAppliedFilter: {
            type: String,
            default: 'all'
        }
    },
    data() {
        return {
            currentSelectedRef: {value: this.initialAppliedFilter}
        }
    },
    methods: {
        changeFilterRef(option) {
            if ((this.currentSelectedRef) && (this.currentSelectedRef.value === option.value)) {
                this.currentSelectedRef = {value: this.$t('COMPLAINT_SIDEBAR.COMPLAINT_FILTER.ALL_REF')};
            } else {
                this.currentSelectedRef = option ;
            }
        },
        removeFilter() {
            this.currentSelectedRef = {value: this.$t('COMPLAINT_SIDEBAR.COMPLAINT_FILTER.ALL_REF')};
        },
        submitFilterRef() {
            this.$emit('applyFilter', this.currentSelectedRef.value);
        }
    }
}
</script>

<style scoped lang="scss">
.filters-wrap {
  padding: var(--space-normal);
  border-radius: var(--border-radius-large);
  border: 1px solid var(--color-border);
  background: var(--color-background-light);
  margin-bottom: var(--space-normal);
}
.filter-inputs {
  display: flex;
}
</style>
