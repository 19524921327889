<template>
  <div class="column content-box">
      <!-- List Canned Response -->
      <div class="row">
          <div class="columns with-right-space">
              <div

                      v-if="isAdmin"

              >

                  <woot-button
                          color-scheme="primary"
                          class-names="  "
                          icon="add-circle"
                          class="float-right mt-2 mb-5"
                  >
                      {{ $t('SETTINGS.INBOXES.NEW_INBOX') }}
                  </woot-button>

              </div>
              <p v-if="!inboxesList.length" class="no-items-error-message">
                  {{ $t('INBOX_MGMT.LIST.404') }}
                  <div
                          v-if="isAdmin"

                  >
                      {{ $t('SETTINGS.INBOXES.NEW_INBOX') }}
                  </div>
              </p>
              <table v-if="inboxesList.length" class="table table-hover">
                  <tbody>
                  <tr>
                      <th>#</th>
                      <th>{{ $t('SETTINGS.INBOXES.SHORT_CODE') }}</th>
                      <th class="">{{ $t('SETTINGS.INBOXES.ACTIONS') }}</th>
                  </tr>
                  <tr v-for="item in currentInboxes" :key="item.id">
                      <td>
                          <img
                                  v-if="item.avatar_url"
                                  class="woot-thumbnail"
                                  :src="item.avatar_url"
                                  alt="No Page Image"
                                  height="35px"
                                  width="35px"
                          />
                          <img
                                  v-else
                                  class="woot-thumbnail"
                                  src="~dashboard/assets/images/flag.svg"
                                  alt="No Page Image"
                                  height="35px"
                                  width="35px"
                          />
                      </td>
                      <!-- Short Code  -->
                      <td>
                          <span class="agent-name">{{ item.name }}</span>
                          <span v-if="item.channel_type === 'Channel::FacebookPage'">
                Facebook
              </span>
                          <span v-if="item.channel_type === 'Channel::WebWidget'">
                Website
              </span>
                          <span v-if="item.channel_type === 'Channel::TwitterProfile'">
                Twitter
              </span>
                          <span v-if="item.channel_type === 'Channel::TwilioSms'">
                {{ twilioChannelName(item) }}
              </span>
                          <span v-if="item.channel_type === 'Channel::Whatsapp'">
                Whatsapp
              </span>
                          <span v-if="item.channel_type === 'Channel::Sms'">
                Sms
              </span>
                          <span v-if="item.channel_type === 'Channel::Email'">
                Email
              </span>
                          <span v-if="item.channel_type === 'Channel::Telegram'">
                Telegram
              </span>
                          <span v-if="item.channel_type === 'Channel::Line'">Line</span>
                          <span v-if="item.channel_type === 'Channel::Api'">
                {{ globalConfig.apiChannelName || 'API' }}
              </span>
                      </td>
                      <!-- Action Buttons -->
                      <td class="actions">
                          <div class="actions-icons d-flex ">
                              <div class="btn icon-btn icon-btn-success">
                                  <router-link
                                          :to="addAccountScoping(`settings/inboxes/${item.id}`)"
                                  >
                                      <svg width="17" height="17" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M4.61666 16.2666C4.10832 16.2666 3.63332 16.0916 3.29166 15.7666C2.85832 15.3583 2.64999 14.7416 2.72499 14.075L3.03332 11.375C3.09166 10.8666 3.39999 10.1916 3.75832 9.82496L10.6 2.58329C12.3083 0.774959 14.0917 0.72496 15.9 2.43329C17.7083 4.14163 17.7583 5.92496 16.05 7.73329L9.20832 14.975C8.85832 15.35 8.20832 15.7 7.69999 15.7833L5.01666 16.2416C4.87499 16.25 4.74999 16.2666 4.61666 16.2666ZM13.275 2.42496C12.6333 2.42496 12.075 2.82496 11.5083 3.42496L4.66666 10.675C4.49999 10.85 4.30832 11.2666 4.27499 11.5083L3.96666 14.2083C3.93332 14.4833 3.99999 14.7083 4.14999 14.85C4.29999 14.9916 4.52499 15.0416 4.79999 15L7.48332 14.5416C7.72499 14.5 8.12499 14.2833 8.29166 14.1083L15.1333 6.86663C16.1667 5.76663 16.5417 4.74996 15.0333 3.33329C14.3667 2.69163 13.7917 2.42496 13.275 2.42496Z" fill="#656877"/>
                                          <path d="M14.4502 9.12504C14.4335 9.12504 14.4085 9.12504 14.3919 9.12504C11.7919 8.8667 9.7002 6.8917 9.3002 4.30837C9.2502 3.9667 9.48353 3.65004 9.8252 3.5917C10.1669 3.5417 10.4835 3.77504 10.5419 4.1167C10.8585 6.13337 12.4919 7.68337 14.5252 7.88337C14.8669 7.9167 15.1169 8.22504 15.0835 8.5667C15.0419 8.88337 14.7669 9.12504 14.4502 9.12504Z" fill="#656877"/>
                                          <path d="M17.5 18.9584H2.5C2.15833 18.9584 1.875 18.675 1.875 18.3334C1.875 17.9917 2.15833 17.7084 2.5 17.7084H17.5C17.8417 17.7084 18.125 17.9917 18.125 18.3334C18.125 18.675 17.8417 18.9584 17.5 18.9584Z" fill="#656877"/>
                                      </svg>
                                  </router-link>
                              </div>
                              <div class="btn icon-btn icon-btn-danger"  @click="openDelete(item)">
                                  <svg width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M21 6.73001C20.98 6.73001 20.95 6.73001 20.92 6.73001C15.63 6.20001 10.35 6.00001 5.12001 6.53001L3.08001 6.73001C2.66001 6.77001 2.29001 6.47001 2.25001 6.05001C2.21001 5.63001 2.51001 5.27001 2.92001 5.23001L4.96001 5.03001C10.28 4.49001 15.67 4.70001 21.07 5.23001C21.48 5.27001 21.78 5.64001 21.74 6.05001C21.71 6.44001 21.38 6.73001 21 6.73001Z" fill="#292D32"></path>
                                      <path d="M8.50001 5.72C8.46001 5.72 8.42001 5.72 8.37001 5.71C7.97001 5.64 7.69001 5.25 7.76001 4.85L7.98001 3.54C8.14001 2.58 8.36001 1.25 10.69 1.25H13.31C15.65 1.25 15.87 2.63 16.02 3.55L16.24 4.85C16.31 5.26 16.03 5.65 15.63 5.71C15.22 5.78 14.83 5.5 14.77 5.1L14.55 3.8C14.41 2.93 14.38 2.76 13.32 2.76H10.7C9.64001 2.76 9.62001 2.9 9.47001 3.79L9.24001 5.09C9.18001 5.46 8.86001 5.72 8.50001 5.72Z" fill="#292D32"></path>
                                      <path d="M15.21 22.75H8.78999C5.29999 22.75 5.15999 20.82 5.04999 19.26L4.39999 9.19C4.36999 8.78 4.68999 8.42 5.09999 8.39C5.51999 8.37 5.86999 8.68 5.89999 9.09L6.54999 19.16C6.65999 20.68 6.69999 21.25 8.78999 21.25H15.21C17.31 21.25 17.35 20.68 17.45 19.16L18.1 9.09C18.13 8.68 18.49 8.37 18.9 8.39C19.31 8.42 19.63 8.77 19.6 9.19L18.95 19.26C18.84 20.82 18.7 22.75 15.21 22.75Z" fill="#292D32"></path>
                                      <path d="M13.66 17.25H10.33C9.91999 17.25 9.57999 16.91 9.57999 16.5C9.57999 16.09 9.91999 15.75 10.33 15.75H13.66C14.07 15.75 14.41 16.09 14.41 16.5C14.41 16.91 14.07 17.25 13.66 17.25Z" fill="#292D32"></path>
                                      <path d="M14.5 13.25H9.5C9.09 13.25 8.75 12.91 8.75 12.5C8.75 12.09 9.09 11.75 9.5 11.75H14.5C14.91 11.75 15.25 12.09 15.25 12.5C15.25 12.91 14.91 13.25 14.5 13.25Z" fill="#292D32"></path>
                                  </svg>
                              </div>
                          </div>
                      </td>
                  </tr>
                  </tbody>
              </table>
          </div>
      </div>
      <table-footer
              :current-page="currentPage"
              :total-count="total === 0 ? inboxesList.length : total"
              :page-size="size"
              @page-change="onPageChange"
      />
      <settings
              v-if="showSettings"
              :show.sync="showSettings"
              :on-close="closeSettings"
              :inbox="selectedInbox"
      />

      <woot-confirm-delete-modal
              v-if="showDeletePopup"
              :show.sync="showDeletePopup"
              :title="$t('INBOX_MGMT.DELETE.CONFIRM.TITLE')"
              :message="confirmDeleteMessage"
              :confirm-text="deleteConfirmText"
              :reject-text="deleteRejectText"
              :confirm-value="selectedInbox.name"
              :confirm-place-holder-text="confirmPlaceHolderText"
              @on-confirm="confirmDeletion"
              @on-close="closeDelete"
      />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Settings from './Settings';
import adminMixin from '../../../../mixins/isAdmin';
import accountMixin from '../../../../mixins/account';
import globalConfigMixin from 'shared/mixins/globalConfigMixin';
import TableFooter from 'dashboard/components/widgets/TableFooter';
import router from "../../../index";

export default {
  components: {
      Settings,
      TableFooter,
  },
  mixins: [adminMixin, accountMixin, globalConfigMixin],
  data() {
      return {
          loading: {},
          showSettings: false,
          showDeletePopup: false,
          selectedInbox: {},
          size: 5,
          currentPage: 1,
          total: 0,
      };
  },
  computed: {
      ...mapGetters({
          inboxesList: 'inboxes/getInboxes',
          globalConfig: 'globalConfig/get',
      }),
      // Delete Modal
      deleteConfirmText() {
          return `${this.$t('INBOX_MGMT.DELETE.CONFIRM.YES')} ${
              this.selectedInbox.name
          }`;
      },
      deleteRejectText() {
          return `${this.$t('INBOX_MGMT.DELETE.CONFIRM.NO')} ${
              this.selectedInbox.name
          }`;
      },
      confirmDeleteMessage() {
          return `${this.$t('INBOX_MGMT.DELETE.CONFIRM.MESSAGE')} ${
              this.selectedInbox.name
          } ?`;
      },
      confirmPlaceHolderText() {
          return `${this.$t('INBOX_MGMT.DELETE.CONFIRM.PLACE_HOLDER', {
              inboxName: this.selectedInbox.name,
          })}`;
      },
      currentInboxes() {
          return this.inboxesList.slice(
              (this.currentPage - 1) * this.size,
              ((this.currentPage - 1) * this.size) + this.size
          )
      }
  },
  methods: {
      twilioChannelName(item) {
          const { medium = '' } = item;
          if (medium === 'whatsapp') return 'WhatsApp';
          return 'Twilio SMS';
      },
      openSettings(inbox) {
          this.showSettings = true;
          this.selectedInbox = inbox;
      },
      closeSettings() {
          this.showSettings = false;
          this.selectedInbox = {};
      },
      async deleteInbox({ id }) {
          try {
              await this.$store.dispatch('inboxes/delete', id);
              this.onChangeTotal();
              bus.$emit(
                  'newToastMessage',
                  this.$t('INBOX_MGMT.DELETE.API.SUCCESS_MESSAGE')
              );
          } catch (error) {
              bus.$emit(
                  'newToastMessage',
                  this.$t('INBOX_MGMT.DELETE.API.ERROR_MESSAGE')
              );
          }
      },

      confirmDeletion() {
          this.deleteInbox(this.selectedInbox);
          this.closeDelete();
      },
      openDelete(inbox) {
          this.showDeletePopup = true;
          this.selectedInbox = inbox;
      },
      closeDelete() {
          this.showDeletePopup = false;
          this.selectedInbox = {};
      },
      onPageChange(page) {
          this.currentPage = page;
      },
      onChangeTotal() {
          const prevCount = this.total;
          const inboxes = this.$store.getters['inboxes/getInboxes'];
          this.total = inboxes.length ;
          /* return to the previous page when the last element of the current page is deleted */
          if (this.total < prevCount &&  this.total % 5 === 0) {
              this.currentPage = this.currentPage - 1 ;
          }
      },
  },
};
</script>
