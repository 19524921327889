import { render, staticRenderFns } from "./CreditsHistory.vue?vue&type=template&id=1d445e72&scoped=true&"
import script from "./CreditsHistory.vue?vue&type=script&lang=js&"
export * from "./CreditsHistory.vue?vue&type=script&lang=js&"
import style0 from "./CreditsHistory.vue?vue&type=style&index=0&id=1d445e72&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d445e72",
  null
  
)

export default component.exports