<template>
    <li
            class="option selectOption"
            :class="{ 'is-selected': isOptionSelected }"
            :style="{ borderColor: widgetColor }"
    >
      <button class="option-button button "
              :class="{ 'd-none': action.title  === 'clic&collect   0 DT',
                          'validationBtn': action.title  === 'Valider' && productSize.length === 0 && this.contentType === 'cards_products' }"
              @click="onClick">
        <div v-html="svgMarkup"></div>
        <span>{{action.title | replaceCarrefour}}</span>
      </button>
    </li>
</template>

<script>
import { mapGetters , mapActions } from 'vuex';
import axios from 'axios';

export default {
    components: {},
    props: {
        action: {
            type: Object,
            default: () => {},
        },
        isSelected: {
            type: Boolean,
            default: false,
        },
        productsItem: {
            type: Array,
            default: () => [],
        },
        contentType : {
            type : String ,
            default : ''
        }
    },
    data() {
        return {
            isCarrefourMarket: false,
            isCarrefourExpress: false,
            newProductItems : []
        };
    },
    computed: {
        ...mapGetters({
            widgetColor: 'appConfig/getWidgetColor',
            productSize: 'appConfig/productSize',
            senderId: 'appConfig/senderId',
            conversationId: 'appConfig/conversationId',
            accountId: 'appConfig/accountId',
            inboxId : 'appConfig/inboxId'
        }),
        svgMarkup() {
            // Generate the SVG markup as a string
            const svgM = `
                <svg class="mr-1" width="18" height="18" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.0731 6.53659C16.0488 2.02927 12.1171 0 8.66339 0C8.66339 0 8.66339 0 8.65364 0C5.20973 0 1.26827 2.01951 0.243881 6.52683C-0.897582 11.561 2.18534 15.8244 4.97559 18.5073C6.00973 19.5024 7.33656 20 8.66339 20C9.99022 20 11.3171 19.5024 12.3414 18.5073C15.1317 15.8244 18.2146 11.5707 17.0731 6.53659Z" fill="#004E9F"/>
                <path d="M6.85941 6.77924L4.15569 9.39488C4.02066 9.50954 3.93442 9.64419 3.93442 9.83685C3.93442 10.0286 4.02075 10.1638 4.15569 10.2793L6.85941 12.8941C6.87678 12.9118 6.89356 12.9189 6.90782 12.9189C6.93343 12.9189 6.95109 12.8956 6.95031 12.8683C6.94954 12.8522 6.94255 12.8336 6.92654 12.8176C6.29191 12.0291 5.83016 11.1248 5.83016 9.84635C5.83016 8.5671 6.29191 7.64504 6.92654 6.85597C6.94255 6.84016 6.94953 6.82192 6.95031 6.80543C6.95118 6.77749 6.93343 6.75489 6.90782 6.75489C6.89356 6.75461 6.87678 6.76158 6.85941 6.77924" fill="white"/>
                <path d="M8.93802 6.55762C7.87937 6.55762 7.46573 8.05785 7.46573 9.84601C7.46573 11.6347 7.87937 13.115 8.93802 13.115C9.57333 13.115 10.1148 12.743 10.1166 12.4372C10.1169 12.3703 10.0911 12.3061 10.0349 12.2502C9.73378 11.9592 9.61602 11.6685 9.61514 11.4119C9.6132 10.9221 10.0381 10.5573 10.4298 10.5573C10.9683 10.5573 11.2859 10.9805 11.2859 11.5286C11.2859 12.048 11.0646 12.4806 10.8239 12.827C10.8144 12.8411 10.8101 12.857 10.8101 12.8708C10.8103 12.898 10.8263 12.9203 10.8503 12.9203C10.8648 12.9203 10.8819 12.9125 10.9005 12.8938L13.6049 10.2791C13.7399 10.1636 13.8262 10.0284 13.8262 9.8366C13.8262 9.64403 13.7398 9.50929 13.6049 9.39463L10.9005 6.77899C10.882 6.76046 10.8649 6.7526 10.8503 6.7526C10.8261 6.7526 10.8103 6.77511 10.8101 6.80237C10.8101 6.81634 10.8144 6.83196 10.8239 6.84631C11.0645 7.19225 11.2859 7.62529 11.2859 8.14429C11.2859 8.692 10.9683 9.11563 10.4298 9.11563C10.0381 9.11563 9.6132 8.75107 9.61514 8.26118C9.61601 8.00459 9.73378 7.71357 10.0349 7.42293C10.0911 7.36667 10.1169 7.30313 10.1166 7.23609C10.1148 6.92984 9.57333 6.55762 8.93802 6.55762Z" fill="white"/>
                </svg>
                        `;
            const svgE = `
                <svg class="mr-1" width="18" height="18" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.0731 6.53659C16.0488 2.02927 12.1171 0 8.66339 0C8.66339 0 8.66339 0 8.65364 0C5.20973 0 1.26827 2.01951 0.243881 6.52683C-0.897582 11.561 2.18534 15.8244 4.97559 18.5073C6.00973 19.5024 7.33656 20 8.66339 20C9.99022 20 11.3171 19.5024 12.3414 18.5073C15.1317 15.8244 18.2146 11.5707 17.0731 6.53659Z" fill="#008E43"/>
                <path d="M6.85941 6.77924L4.15569 9.39488C4.02066 9.50954 3.93442 9.64419 3.93442 9.83685C3.93442 10.0286 4.02075 10.1638 4.15569 10.2793L6.85941 12.8941C6.87678 12.9118 6.89356 12.9189 6.90782 12.9189C6.93343 12.9189 6.95109 12.8956 6.95031 12.8683C6.94954 12.8522 6.94255 12.8336 6.92654 12.8176C6.29191 12.0291 5.83016 11.1248 5.83016 9.84635C5.83016 8.5671 6.29191 7.64504 6.92654 6.85597C6.94255 6.84016 6.94953 6.82192 6.95031 6.80543C6.95118 6.77749 6.93343 6.75489 6.90782 6.75489C6.89356 6.75461 6.87678 6.76158 6.85941 6.77924" fill="white"/>
                <path d="M8.93802 6.55762C7.87937 6.55762 7.46573 8.05785 7.46573 9.84601C7.46573 11.6347 7.87937 13.115 8.93802 13.115C9.57333 13.115 10.1148 12.743 10.1166 12.4372C10.1169 12.3703 10.0911 12.3061 10.0349 12.2502C9.73378 11.9592 9.61602 11.6685 9.61514 11.4119C9.6132 10.9221 10.0381 10.5573 10.4298 10.5573C10.9683 10.5573 11.2859 10.9805 11.2859 11.5286C11.2859 12.048 11.0646 12.4806 10.8239 12.827C10.8144 12.8411 10.8101 12.857 10.8101 12.8708C10.8103 12.898 10.8263 12.9203 10.8503 12.9203C10.8648 12.9203 10.8819 12.9125 10.9005 12.8938L13.6049 10.2791C13.7399 10.1636 13.8262 10.0284 13.8262 9.8366C13.8262 9.64403 13.7398 9.50929 13.6049 9.39463L10.9005 6.77899C10.882 6.76046 10.8649 6.7526 10.8503 6.7526C10.8261 6.7526 10.8103 6.77511 10.8101 6.80237C10.8101 6.81634 10.8144 6.83196 10.8239 6.84631C11.0645 7.19225 11.2859 7.62529 11.2859 8.14429C11.2859 8.692 10.9683 9.11563 10.4298 9.11563C10.0381 9.11563 9.6132 8.75107 9.61514 8.26118C9.61601 8.00459 9.73378 7.71357 10.0349 7.42293C10.0911 7.36667 10.1169 7.30313 10.1166 7.23609C10.1148 6.92984 9.57333 6.55762 8.93802 6.55762Z" fill="white"/>
                </svg>
            `;
            const svgG = `
                <svg class="mr-1" width="18" height="18" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.0731 6.53659C16.0488 2.02927 12.1171 0 8.66339 0C8.66339 0 8.66339 0 8.65364 0C5.20973 0 1.26827 2.01951 0.243881 6.52683C-0.897582 11.561 2.18534 15.8244 4.97559 18.5073C6.00973 19.5024 7.33656 20 8.66339 20C9.99022 20 11.3171 19.5024 12.3414 18.5073C15.1317 15.8244 18.2146 11.5707 17.0731 6.53659Z" fill="#E3000D"/>
                <path d="M6.85941 6.77924L4.15569 9.39488C4.02066 9.50954 3.93442 9.64419 3.93442 9.83685C3.93442 10.0286 4.02075 10.1638 4.15569 10.2793L6.85941 12.8941C6.87678 12.9118 6.89356 12.9189 6.90782 12.9189C6.93343 12.9189 6.95109 12.8956 6.95031 12.8683C6.94954 12.8522 6.94255 12.8336 6.92654 12.8176C6.29191 12.0291 5.83016 11.1248 5.83016 9.84635C5.83016 8.5671 6.29191 7.64504 6.92654 6.85597C6.94255 6.84016 6.94953 6.82192 6.95031 6.80543C6.95118 6.77749 6.93343 6.75489 6.90782 6.75489C6.89356 6.75461 6.87678 6.76158 6.85941 6.77924Z" fill="white"/>
                <path d="M8.93802 6.55762C7.87937 6.55762 7.46573 8.05785 7.46573 9.84601C7.46573 11.6347 7.87937 13.115 8.93802 13.115C9.57333 13.115 10.1148 12.743 10.1166 12.4372C10.1169 12.3703 10.0911 12.3061 10.0349 12.2502C9.73378 11.9592 9.61602 11.6685 9.61514 11.4119C9.6132 10.9221 10.0381 10.5573 10.4298 10.5573C10.9683 10.5573 11.2859 10.9805 11.2859 11.5286C11.2859 12.048 11.0646 12.4806 10.8239 12.827C10.8144 12.8411 10.8101 12.857 10.8101 12.8708C10.8103 12.898 10.8263 12.9203 10.8503 12.9203C10.8648 12.9203 10.8819 12.9125 10.9005 12.8938L13.6049 10.2791C13.7399 10.1636 13.8262 10.0284 13.8262 9.8366C13.8262 9.64403 13.7398 9.50929 13.6049 9.39463L10.9005 6.77899C10.882 6.76046 10.8649 6.7526 10.8503 6.7526C10.8261 6.7526 10.8103 6.77511 10.8101 6.80237C10.8101 6.81634 10.8144 6.83196 10.8239 6.84631C11.0645 7.19225 11.2859 7.62529 11.2859 8.14429C11.2859 8.692 10.9683 9.11563 10.4298 9.11563C10.0381 9.11563 9.6132 8.75107 9.61514 8.26118C9.61601 8.00459 9.73378 7.71357 10.0349 7.42293C10.0911 7.36667 10.1169 7.30313 10.1166 7.23609C10.1148 6.92984 9.57333 6.55762 8.93802 6.55762Z" fill="white"/>
                </svg>
            `;
            if(this.action.title.includes('market')){
                return svgM;
            }else if (this.action.title.includes('express')){
                return svgE;
            }else if(this.action.title.includes('Mall of Sousse') || this.action.title.includes('Mall of sousse')){
                return svgG;
            }else if(this.action.title.includes('Gab')){
                return svgG;
            }else if(this.action.title.includes('CM')){
                return svgM;
            }
            else if(this.action.title.includes('Marsa')){
                return svgG;
            }
            else if(this.action.title.includes('CE')){
                return svgG;
            }
        }
    },
    methods: {
      isOptionSelected(option) {
            return this.selected === option.id;
        },
        ...mapActions('appConfig', [
            'setAppConfig',
            'setReferrerHost',
            'setWidgetColor',
            'setProductSize',
        ]),
        onClick() {
            if (this.contentType === 'cards_products') {
                this.$emit('click',  this.productSize);
            }
            if(this.action.title === 'Ignorer'){
                this.productSize = [];
                this.setProductSize([]);
                console.warn('Products table : ',this.productSize)
            }
            this.$emit('click', this.action);
        },
    },
    filters: {
        replaceCarrefour: function(value) {
            return value.replace('Carrefour market', '').replace('Carrefour express', '');
        },
    },
};
</script>



<style scoped lang="scss">
@import '~widget/assets/scss/variables.scss';

.option {
  border-radius: $space-jumbo;
  border: 1px solid $color-woot;
  float: left;
  margin: 0.25rem 1px;
  max-width: 100%;

  .option-button {
    background: transparent;
    border-radius: $space-large;
    border: 0;
    cursor: pointer;
    height: auto;
    line-height: 1.5;
    min-height: $space-two * 2;
    text-align: left;
    white-space: normal;
    display: flex ;
    align-items: center !important;
    padding: 10px !important;
    font-weight: 500 !important;

    span {
      display: inline-block;
      vertical-align: middle;
    }

    > .icon {
      margin-right: $space-smaller;
      font-size: $font-size-medium;
    }
  }
}

.customCareffourBtn{
  display: none !important;
}
.selectOption{
  border-radius: 8px !important;
  border: 1px solid var(--convergence-10, #DAD8EB) !important;
  background: var(--white, #FFF) !important;
  color: var(--convergence-main, #483A9D) !important;

  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  cursor: pointer !important;
  button{
    color: #656877;
    font-size: 12px !important;
  }
}

.button:focus{
  outline: none !important;
}

.validationBtn{
  display: none !important;
}

.selectOption:hover{
  border-radius: 8px;
  border: 1px solid var(--convergence-10, #DAD8EB);
  background: var(--convergence-10, #EDECF6);
  button:hover{
    color: #483A9D;
  }
}

.d-none{
  display: none !important;
}
</style>
