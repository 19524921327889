<template>
    <div
            class="selector-wrap"
            :class="{'error-margin': newConv}"
            style="margin-right: 15rem!important;"
    >
        <woot-button
                variant="hollow"
                color-scheme="secondary"
                class="selector-button"
                :class="{ 'rounded-button': report, 'error-button': (newConv && notSelectedInbox) || (newCampaign && notSelectedInbox) }"
                @click.prevent="toggleDropdown"
        >
            <div class="selector-user-wrap">
                <div class="selector-name-wrap">
                    <h4
                            class="selector-name text-truncate text-block-title"
                            :title="selectedItem.name"
                    >
                        {{ selectedItem.name }}
                    </h4>
                    <i v-if="showSearchDropdown" class="icon ion-chevron-up" />
                    <i v-else class="icon ion-chevron-down" />
                </div>
            </div>
        </woot-button>
        <div
                v-on-clickaway="
                  () => {
                    onCloseDropdown();
                  }
                "
                :class="{ 'dropdown-pane--open': showSearchDropdown }"
                class="dropdown-pane"
        >
            <div class="dropdown__header">
                <h4 v-if="options.length === 0 && isOnOff" class="text-block-title text-truncate">
                    {{ $t('CAMPAIGN.ADD.FORM.INBOX.NO_RESULTS') }}
                </h4>
                <h4 v-else class="text-block-title text-truncate">
                    {{ multiselectorTitle }}
                </h4>
                <woot-button
                        icon="dismiss"
                        size="tiny"
                        color-scheme="secondary"
                        variant="clear"
                        @click.prevent="onCloseDropdown"
                />
            </div>
            <multiselect-dropdown-items
                    v-if="showSearchDropdown && !isOnOff"
                    :options="options"
                    :selected-items="selectedItem"
                    :input-placeholder="inputPlaceholder"
                    :no-search-result="noSearchResult"
                    :has-thumbnail="false"
                    :is-country="false"
                    :is-inbox="true"
                    :new-conv="newConv"
                    @click="onClickSelectItem"
            />
        </div>
    </div>
</template>

<script>
import MultiselectDropdownItems from 'shared/components/ui/MultiselectDropdownItems';
import { mixin as clickaway } from 'vue-clickaway';
export default {
    components: {
        MultiselectDropdownItems,
    },
    mixins: [clickaway],
    props: {
        options: {
            type: Array,
            default: () => [],
        },
        selectedItem: {
            type: Object,
            default: () => ({}) ,
        },
        multiselectorTitle: {
            type: String,
            default: '',
        },
        /*multiselectorPlaceholder: {
            type: String,
            default: 'None',
        },*/
        noSearchResult: {
            type: String,
            default: 'No results found',
        },
        inputPlaceholder: {
            type: String,
            default: 'Search',
        },
        newConv: {
            type: Boolean,
            default: false,
        },
        isOnOff: {
            type: Boolean,
            default: false,
        },
        report: {
            type: Boolean,
            default: false,
        },
        notSelectedInbox: {
            type: Boolean,
            default: false,
        },
        newCampaign: {
            type: Boolean,
            default: false,
        },
        isDateOpen: {
          type: Boolean,
          default: false,
        }
    },
    data() {
        return {
            showSearchDropdown: false ,
        };
    },
    watch: {
      isDateOpen(updatedValue) {
        if(updatedValue === true) {
          this.showSearchDropdown = false;
        }
        this.isDateOpen = false;
      }
    },
    computed: {
        hasValue() {
            if (this.selectedItem && this.selectedItem.id) {
                return true;
            }
            return false;
        },
    },
    methods: {
        toggleDropdown() {
            this.showSearchDropdown = !this.showSearchDropdown;
        },
        onCloseDropdown() {
            this.showSearchDropdown = false;
        },

        onClickSelectItem(value) {
            this.$emit('click', value);
            this.onCloseDropdown();
        },
    },
};
</script>

<style lang="scss" scoped>

.error-margin {
  margin-bottom: 0 !important;
}
.selector-wrap {
  position: relative;
  width: 100%;
  margin-right: var(--space-one);
  margin-bottom: var(--space-small);
  background-color: white;

  .rounded-button {
    border-radius: 8px;
    border-color: #a8a8a8 !important;
  }
  .error-button {
    border-color: var(--r-400) !important;
  }

  .selector-button {
    width: 100%;
    border: 1px solid var(--s-200);
    padding-left: var(--space-one);
    padding-right: var(--space-one);

    &:hover {
      border: 1px solid var(--color-border);
    }
  }

  .selector-user-wrap {
    display: flex;
  }

  .selector-name-wrap {
    display: flex;
    justify-content: space-between;
    width: 100%;
    min-width: 0;
    align-items: center;
  }

  .not-selected {
    margin: 0 var(--space-small) 0 0;
  }

  .selector-name {
    align-items: center;
    line-height: 1.2;
    margin: 0 var(--space-small);
  }

  .dropdown-pane {
    box-sizing: border-box;
    top: 4.2rem;
    width: 100% !important;
  }
}

.dropdown__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--space-smaller);

  .text-block-title {
    margin: 0;
  }
}
</style>
