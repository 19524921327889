<template>
    <div class="settings-header d-flex justify-space-between" style="z-index: 999;">
        <div v-if="headerTitle == $t('OVERVIEW_REPORTS.HEADER')">
            <h2 class="display-10 mt-2">{{ $t('OVERVIEW_REPORTS.HEADER') }}</h2>
        </div>
        <div class="d-flex" v-else>
            <woot-sidemenu-icon style="margin-top: 15px;" />
            <div v-if="!inboxExists" class="breadcrumbs p-2 ml-2">
                <h2 class="display-10">{{ headerTitle }} </h2>
                <div aria-label="Breadcrumb" class="breadcrumb-fl">
                    <ul>
                        <li v-if="fromSettings"><span class="font-inter display-ul" ref="#"> {{ $t('SIDEBAR.SETTINGS') }}</span></li>
                        <li>
              <span class="font-inter display-ul" ref="#"
              >{{ headerTitle }} </span
              >
                        </li>
                        <li>
              <span class="bread_active display-ul font-inter" ref="#"
              > details </span
              >
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="notif_block">
            <agent-details @toggle-menu="toggleOptions" />
            <options-menu
                    :show="showOptionsMenu"
                    @toggle-accounts="toggleAccountModal"
                    @show-support-chat-window="toggleSupportChatWindow"
                    @key-shortcut-modal="$emit('key-shortcut-modal')"
                    @close="toggleOptions"
            />
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import BackButton from '../../../components/widgets/BackButton';
import adminMixin from '../../../mixins/isAdmin';
import NotificationBell from '../../../components/layout/sidebarComponents/NotificationBell';
import AgentDetails from '../../../components/layout/sidebarComponents/AgentDetails';
import OptionsMenu from '../../../components/layout/sidebarComponents/OptionsMenu';

export default {
    components: {
        OptionsMenu,
        AgentDetails,
        BackButton,
        NotificationBell,
    },
    data() {
        return {
            showOptionsMenu: false,
            inboxExists: false,
        };
    },
    mixins: [adminMixin],
    props: {
        headerTitle: {
            default: '',
            type: String,
        },
        buttonRoute: {
            default: '',
            type: String,
        },
        buttonText: {
            default: '',
            type: String,
        },
        icon: {
            default: '',
            type: String,
        },
        showBackButton: { type: Boolean, default: false },
        showNewButton: { type: Boolean, default: false },
        backUrl: {
            type: [String, Object],
            default: '',
        },
        backButtonLabel: {
            type: String,
            default: '',
        },
        showSidemenuIcon: {
            type: Boolean,
            default: true,
        },
    },
    methods: {
        openNotificationPanel() {
            this.$emit('open-notification-panel');
        },
        toggleOptions() {
            this.showOptionsMenu = !this.showOptionsMenu;
        },
        toggleAccountModal() {
            this.$emit('toggle-accounts');
        },
        toggleSupportChatWindow() {
            window.$convergence.toggle();
        },
    },
    mounted() {
        if (window.location.pathname.includes('contacts/')) {
            this.inboxExists = true;
        }
    },
    computed: {
        ...mapGetters({
            currentUser: 'getCurrentUser',
        }),
        iconClass() {
            return `icon ${this.icon} header--icon`;
        },
        /* check if the header displayed is based on settings */
        fromSettings() {
            if (this.headerTitle === this.$t('REPORT.HEADER') || this.headerTitle === this.$t('CSAT_REPORTS.HEADER') ||
                this.headerTitle === this.$t('AGENT_REPORTS.HEADER') || this.headerTitle === this.$t('LABEL_REPORTS.HEADER') ||
                this.headerTitle === this.$t('INBOX_REPORTS.HEADER') || this.headerTitle === this.$t('TEAM_REPORTS.HEADER') ||
                this.headerTitle === this.$t('CAMPAIGN.ONGOING.HEADER') || this.headerTitle === this.$t('CAMPAIGN.ONE_OFF.HEADER')) {

                return false;
            }
            else {
                return true;
            }
        }
    },
};
</script>
