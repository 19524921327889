<template>
  <li v-show="(isMenuItemVisible && menuItem.label !== 'LABELS' && accountId !== 1) || (isMenuItemVisible && accountId === 1)" class="sidebar-item">
    <div v-if="hasSubMenu" class="secondary-menu--wrap">
      <div class="d-flex">
        <fluent-icon
          :icon="menuItem.icon"
          class="secondary-menu--icon custom_marg"
          size="21"
        />
        <span class="secondary-menu--header marg7 fs-small">
          {{ $t(`SIDEBAR.${menuItem.label}`) }}
        </span>
        <div v-if="isHelpCenterSidebar" class="submenu-icons">
          <woot-button
            size="tiny"
            variant="clear"
            color-scheme="secondary"
            class="submenu-icon"
            @click="onClickOpen"
          >
            <fluent-icon icon="add" size="19" />
          </woot-button>
        </div>
      </div>
      <div class="d-flex">
        <ul v-if="hasSubMenu" class="nested vertical menu">
          <router-link
            v-if="showItem(menuItem)"
            v-slot="{ href, isActive, navigate }"
            :to="menuItem.toState"
            custom
          >
            <li>
              <a
                :href="href"
                class="p-0 margin4"
                :class="{ 'is-active': isActive }"
                @click="e => newLinkClick(e, navigate)"
              >
                <svg
                  width="26"
                  height="26"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="32" height="32" rx="8" fill="#EDECF6" />
                  <path
                    d="M20.6223 16.8889H12.089C11.7974 16.8889 11.5557 16.6472 11.5557 16.3556C11.5557 16.064 11.7974 15.8223 12.089 15.8223H20.6223C20.9139 15.8223 21.1557 16.064 21.1557 16.3556C21.1557 16.6472 20.9139 16.8889 20.6223 16.8889Z"
                    fill="#292D32"
                  />
                  <path
                    d="M16.3556 21.1557C16.064 21.1557 15.8223 20.9139 15.8223 20.6223V12.089C15.8223 11.7974 16.064 11.5557 16.3556 11.5557C16.6472 11.5557 16.8889 11.7974 16.8889 12.089V20.6223C16.8889 20.9139 16.6472 21.1557 16.3556 21.1557Z"
                    fill="#292D32"
                  />
                </svg>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </div>
    <router-link
      v-else
      class="secondary-menu--title secondary-menu--link fs-small"
      :class="computedClass"
      :to="menuItem && menuItem.toState"
    >
      <fluent-icon
            :icon="menuItem.icon"
            class="secondary-menu--icon non-clickable"
            size="19"
      />
            <template v-if="menuItem.label === 'ALL_CONTACTS'">
                <a @click="onDisplayAll(menuItem)">
                    {{ $t(`SIDEBAR.${menuItem.label}`) }}
                </a>
            </template>
            <template v-else>
                {{ $t(`SIDEBAR.${menuItem.label}`) }}
            </template>
            <span
                    v-if="isHelpCenterSidebar"
                    class="count-view"
                    :class="computedClass"
            >
        {{ `${menuItem.count}` }}
      </span>
    </router-link>

    <ul v-if="hasSubMenu" class="nested vertical menu">
      <secondary-child-nav-item
        v-for="child in menuItem.children"
        :key="child.id"
        :to="child.toState"
        :label="child.label"
        :label-color="child.color"
        :should-truncate="child.truncateLabel"
        :icon="computedInboxClass(child)"
        :warning-icon="computedInboxErrorClass(child)"
        :is-help-center-sidebar="isHelpCenterSidebar"
        :child-item-count="child.count"
        @click="clearSearch"
      />
      <p v-if="isHelpCenterSidebar && isCategoryEmpty" class="empty-text">
        {{ $t('SIDEBAR.HELP_CENTER.CATEGORY_EMPTY_MESSAGE') }}
      </p>
    </ul>
  </li>
</template>

<script>
import {mapGetters, mapState} from 'vuex';
import { mapMutations } from 'vuex';
import adminMixin from '../../../mixins/isAdmin';
import {
  getInboxClassByType,
  getInboxWarningIconClass,
} from 'dashboard/helper/inbox';

import SecondaryChildNavItem from './SecondaryChildNavItem';

export default {
  components: { SecondaryChildNavItem },
  mixins: [adminMixin],
  props: {
    menuItem: {
      type: Object,
      default: () => ({}),
    },
    isHelpCenterSidebar: {
      type: Boolean,
      default: false,
    },
    isCategoryEmpty: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
        activeInbox: 'getSelectedInbox',
        accountId: 'getCurrentAccountId',
        isFeatureEnabledonAccount: 'accounts/isFeatureEnabledonAccount',
        globalConfig: 'globalConfig/get'
    }),
    ...mapState(['clickedLabel', "searchQuery"]),
    hasSubMenu() {
      return !!this.menuItem.children;
    },
    isMenuItemVisible() {
          if (this.menuItem.globalConfigFlag) {
              return !!this.globalConfig[this.menuItem.globalConfigFlag];
          }
          if (this.menuItem.featureFlag) {
              return this.isFeatureEnabledonAccount(
                  this.accountId,
                  this.menuItem.featureFlag
              );
          }
          return true;
    },
    isInboxConversation() {
      return (
        this.$store.state.route.name === 'inbox_conversation' &&
        this.menuItem.toStateName === 'home'
      );
    },
    isTeamsSettings() {
      return (
        this.$store.state.route.name === 'settings_teams_edit' &&
        this.menuItem.toStateName === 'settings_teams_list'
      );
    },
    isInboxsSettings() {
      return (
        this.$store.state.route.name === 'settings_inbox_show' &&
        this.menuItem.toStateName === 'settings_inbox_list'
      );
    },
    isIntegrationsSettings() {
      return (
        this.$store.state.route.name === 'settings_integrations_webhook' &&
        this.menuItem.toStateName === 'settings_integrations'
      );
    },
    isApplicationsSettings() {
      return (
        this.$store.state.route.name === 'settings_applications_integration' &&
        this.menuItem.toStateName === 'settings_applications'
      );
    },
    isArticlesView() {
      return this.$store.state.route.name === this.menuItem.toStateName;
    },

    computedClass() {
      // If active Inbox is present
      // donot highlight conversations
      if (this.activeInbox) return ' ';
      if (this.hasSubMenu) {
        if (
          this.isInboxConversation ||
          this.isTeamsSettings ||
          this.isInboxsSettings ||
          this.isIntegrationsSettings ||
          this.isApplicationsSettings
        ) {
          return 'is-active';
        }
        return ' ';
      }
      if (this.isHelpCenterSidebar) {
        if (this.isArticlesView) {
          return 'is-active';
        }
        return ' ';
      }
      return '';
    },
  },
  methods: {
      ...mapMutations(['updateClickedLabel']),
     /* check if All CONTACTS has been clicked on */
     onDisplayAll(menuItem) {
       if (menuItem.label === "ALL_CONTACTS") {
           this.$store.dispatch('contacts/clearContactFilters');
           this.$store.dispatch('contacts/get');
       }
    },
    computedInboxClass(child) {
      const { type, phoneNumber } = child;
      if (!type) return '';
      const classByType = getInboxClassByType(type, phoneNumber);
      return classByType;
    },
    computedInboxErrorClass(child) {
      const { type, reauthorizationRequired } = child;
      if (!type) return '';
      const warningClass = getInboxWarningIconClass(
        type,
        reauthorizationRequired
      );
      return warningClass;
    },
    newLinkClick(e, navigate) {
      if (this.menuItem.newLinkRouteName) {
        navigate(e);
      } else if (this.menuItem.showModalForNewItem) {
        if (this.menuItem.modalName === 'AddLabel') {
          e.preventDefault();
          this.$emit('add-label');
        }
      }
    },
    showItem(item) {
      return this.isAdmin && item.newLink !== undefined;
    },
    onClickOpen() {
      this.$emit('open');
    },
      /* verify if label is clicked & search input is not empty to clear it  */
    clearSearch(to) {
      if (this.menuItem.toStateName === "labels_list") {
          if (this.searchQuery !== '') {
              this.updateClickedLabel(true) ;
          }
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.sidebar-item {
  margin: var(--space-smaller) 0 0;
}

.secondary-menu--wrap {
  display: flex;
  justify-content: space-between;
  margin-top: var(--space-small);
}

.secondary-menu--header {
  color: var(--s-700);
  display: flex;
  font-weight: var(--font-weight-bold);
  line-height: var(--space-normal);
  margin: var(--space-small) 0;
  padding: 0 var(--space-small);
}
.secondary-menu--title {
  color: var(--s-600);
  display: flex;
  font-weight: var(--font-weight-medium);
  line-height: var(--space-normal);
  margin: var(--space-small) 0;
  padding: 0 var(--space-small);
}

.secondary-menu--link {
  display: flex;
  align-items: center;
  margin: 0;
  padding: var(--space-small);
  font-weight: var(--font-weight-medium);
  border-radius: var(--border-radius-normal);
  color: var(--s-700);

  &:hover {
    background: #f2f9fc; /* var(--white) */
    color: #007fc9; /* var(--cv-primary) */
  }

  &:focus {
    border-color: var(--w-300);
  }

  &.router-link-exact-active,
  &.is-active {
    background: #f2f9fc; /* #edecf6 */
    color: #007fc9 !important; /* var(--cv-primary) */
    border-color: var(--w-25);
  }
}

.secondary-menu--icon {
  margin-right: var(--space-smaller);
  min-width: var(--space-normal);
}

.non-clickable {
  cursor: default;
}

.sub-menu-link {
  color: var(--s-600);
}

.wrap {
  display: flex;
  align-items: center;
}

.label-color--display {
  border-radius: var(--space-smaller);
  height: var(--space-normal);
  margin-right: var(--space-small);
  min-width: var(--space-normal);
  width: var(--space-normal);
}

.inbox-icon {
  position: relative;
  top: -1px;
}

.sidebar-item .button.menu-item--new {
  display: inline-flex;
  height: var(--space-medium);
  margin: var(--space-smaller) 0;
  padding: var(--space-smaller);
  color: var(--cv-primary);

  &:hover {
    color: var(--cv-black);
  }
}

.beta {
  padding-right: var(--space-smaller) !important;
  padding-left: var(--space-smaller) !important;
  margin-left: var(--space-half) !important;
  display: inline-block;
  font-size: var(--font-size-micro);
  font-weight: var(--font-weight-medium);
  line-height: 18px;
  border: 1px solid transparent;
  border-radius: 2em;
  color: var(--g-800);
  border-color: var(--g-700);
}

.count-view {
  background: var(--s-50);
  border-radius: var(--border-radius-normal);
  color: var(--s-600);
  font-size: var(--font-size-micro);
  font-weight: var(--font-weight-bold);
  margin-left: var(--space-smaller);
  padding: var(--space-zero) var(--space-smaller);

  &.is-active {
    background: #f2f9fc; /* var(--w-50) */
    color: #007fc9; /* var(--w-500) */
  }
}

.submenu-icons {
  display: flex;
  align-items: center;

  .submenu-icon {
    padding: 0;
    margin-left: var(--space-small);
  }
}

.margin4 {
  margin-top: 4px !important;
}

.marg7 {
  margin-left: -15px;
}

.empty-text {
  color: var(--s-500);
  font-size: var(--font-size-small);
  margin: var(--space-smaller);
}

.custom_marg {
  margin-top: 8px !important;
  margin-left: 2px !important;
}
</style>
