<template>
    <div class="row csat--metrics-container w-100">
        <csat-metric-card
                :label="$t('CSAT_REPORTS.METRIC.TOTAL_RESPONSES.LABEL')"
                :info-text="$t('CSAT_REPORTS.METRIC.TOTAL_RESPONSES.TOOLTIP')"
                :value="responseCount"
        />
        <csat-metric-card
                :disabled="ratingFilterEnabled"
                :label="$t('CSAT_REPORTS.METRIC.SATISFACTION_SCORE.LABEL')"
                :info-text="$t('CSAT_REPORTS.METRIC.SATISFACTION_SCORE.TOOLTIP')"
                :value="ratingFilterEnabled ? '--' : formatToPercent(satisfactionScore)"
                :is-satisfaction="true"
        />
        <csat-metric-card
                :label="$t('CSAT_REPORTS.METRIC.RESPONSE_RATE.LABEL')"
                :info-text="$t('CSAT_REPORTS.METRIC.RESPONSE_RATE.TOOLTIP')"
                :value="formatToPercent(responseRate)"
        />
        <div
                v-if="metrics.totalResponseCount && !ratingFilterEnabled"
                class="medium-6 report-card"
                style="text-align: center"
        >
            <h3 class="emoji-container">
                <div class="emoji--distribution">
                    <div
                            v-for="(rating, key, index) in ratingPercentage"
                            :key="rating + key + index"
                            :class="'emoji--distribution-item-' + key"
                    >
            <span class="emoji--distribution-key">{{
                ratingToEmoji(key)
                }}</span>
                        <span>{{ formatToPercent(rating) }}</span>
                    </div>
                </div>
            </h3>
            <div class="emoji--distribution-chart">
                <woot-horizontal-bar :from-csat="true" :collection="chartData" :height="24" @bar-width="setEmojiPosition" />
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import CsatMetricCard from './CsatMetricCard';
import { CSAT_RATINGS } from 'shared/constants/messages';

export default {
    components: {
        CsatMetricCard,
    },
    props: {
        filters: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            csatRatings: CSAT_RATINGS,
        };
    },
    computed: {
        ...mapGetters({
            metrics: 'csat/getMetrics', /* 'newCsat/getMetrics' */
            ratingPercentage: 'csat/getRatingPercentage', /* 'newCsat/getRatingPercentage' */
            satisfactionScore: 'csat/getSatisfactionScore', /* 'newCsat/getSatisfactionScore' */
            responseRate: 'csat/getResponseRate', /* 'newCsat/getResponseRate' */
        }),
        ratingFilterEnabled() {
            return Boolean(this.filters.rating);
        },
        chartData() {
            return {
                labels: ['Rating'],
                datasets: CSAT_RATINGS.map(rating => ({
                    label: rating.emoji,
                    data: [this.ratingPercentage[rating.value]],
                    backgroundColor: rating.color,
                })),
            };
        },
        responseCount() {
            return this.metrics.totalResponseCount
                ? this.metrics.totalResponseCount.toLocaleString()
                : '--';
        },
    },
    methods: {
        formatToPercent(value) {
            return value ? `${value}%` : '--';
        },
        ratingToEmoji(value) {
            return CSAT_RATINGS.find(rating => rating.value === Number(value)).emoji;
        },
        setEmojiPosition(value) {
            // Create an object to store widths according to the key
            const widthMap = {
                1: (value * this.ratingPercentage[1]) / 100,
                2: (value * this.ratingPercentage[2]) / 100,
                3: (value * this.ratingPercentage[3]) / 100,
                4: (value * this.ratingPercentage[4]) / 100,
                5: (value * this.ratingPercentage[5]) / 100,
            };
            for (let key = 1; key <= 5; key++) {
                const elements = document.querySelectorAll(`.emoji--distribution-item-${key}`);
                if (widthMap[key] === 0) {
                    // do not display the emoji if its percentage is null
                    elements.forEach((element) => {
                        element.style.setProperty('display', `none`);
                    });
                }
                else {
                    // Add the width property to each element
                    elements.forEach((element) => {
                        element.style.setProperty('width', `${widthMap[key]}px`)
                    });
                }
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.csat--metrics-container {
  background: var(--white);
  margin-bottom: var(--space-two);
  border-radius: var(--border-radius-normal);
  border: 1px solid var(--color-border);
  padding: var(--space-normal);
}

.emoji-container {
  align-items: center;
  color: var(--color-heading);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-bold);
}

.emoji--distribution {
  display: flex;
  /*justify-content: flex-end;*/

  .emoji--distribution-item {
    /*padding-left: var(--space-normal);*/
  }
}

.emoji--distribution-chart {
  margin-top: var(--space-small);
}

.emoji--distribution-key {
  /*margin-right: var(--space-micro);*/
}
</style>
