<template>
  <transition name="menu-slide">
    <div
      v-if="show"
      v-on-clickaway="onClickAway"
      class="dropdown-pane"
      :class="{ 'dropdown-pane--open': show }"
    >
        <span class="title"> {{$t('SIDEBAR.SET_AVAILABILITY_TITLE')}}</span>
      <availability-status />
        <!-- Divider -->
        <!-- to style autoOffline automatically -->
        <svg width="219" height="1" class="mt-1 mb-1" viewBox="0 0 245 1" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="0.25" y1="0.75" x2="244.75" y2="0.75" stroke="#A8A8A8" stroke-width="0.5" stroke-linecap="round"/>
        </svg>
        <woot-dropdown-menu :style="{'flex-direction':'unset' , 'margin' : '7px 0' }" >
            <woot-dropdown-item class="auto-offline--toggle">
                <div class="info-wrap">
                    <fluent-icon
                            v-tooltip.right-start="$t('SIDEBAR.SET_AUTO_OFFLINE.INFO_TEXT')"
                            icon="info"
                            size="14"
                            class="info-icon"
                    />

                    <span class="auto-offline--text">
                      {{ $t('SIDEBAR.SET_AUTO_OFFLINE.TEXT') }}
                    </span>
                </div>

                <woot-switch
                        size="small"
                        class="auto-offline--switch"
                        :value="currentUserAutoOffline"
                        @input="updateAutoOffline"
                />
            </woot-dropdown-item>
        </woot-dropdown-menu>
        <!-- Divider -->
        <svg width="219" height="1" class="mt-1 mb-1" viewBox="0 0 245 1" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="0.25" y1="0.75" x2="244.75" y2="0.75" stroke="#A8A8A8" stroke-width="0.5" stroke-linecap="round"/>
        </svg>
        <woot-dropdown-menu :style="{'flex-direction':'unset' , 'margin' : '7px 0' }" >
        <woot-dropdown-item v-if="showChangeAccountOption" >
          <woot-button
            variant="clear"
            color-scheme="secondary"
            size="small"
            icon="arrow-swap"
            @click="$emit('toggle-accounts')"
          >
            {{ $t('SIDEBAR_ITEMS.CHANGE_ACCOUNTS') }}
          </woot-button>
        </woot-dropdown-item>
        <woot-dropdown-item v-if="globalConfig.convergenceInboxToken">
          <woot-button
            variant="clear"
            color-scheme="secondary"
            size="small"
            icon="chat-help"
            @click="$emit('show-support-chat-window')"
          >
            {{ $t('SIDEBAR_ITEMS.CONTACT_SUPPORT') }}
          </woot-button>
        </woot-dropdown-item>
          <woot-dropdown-item class="mr-2">
              <woot-button
                      variant="clear"
                      color-scheme="secondary"
                      size="small"
                      icon="power"
                      @click="logout"
                      class="btn btn-logout"
              >
                  {{ $t('SIDEBAR_ITEMS.LOGOUT') }}
              </woot-button>
          </woot-dropdown-item>
<!--          <woot-dropdown-item >
          <router-link
             v-slot="{ href, isActive, navigate }"
             :to="`/app/accounts/${accountId}/profile/settings`"
             custom
             class="btn btn-primary"
           >
             <a
               :href="href"
               class="button small clear secondary"
               :class="{ 'is-active': isActive }"
               @click="e => handleProfileSettingClick(e, navigate)"
             >
               <fluent-icon icon="person" size="14" class="icon icon&#45;&#45;font" />
               <span class="button__content">
                 {{ $t('SIDEBAR_ITEMS.PROFILE_SETTINGS') }}
               </span>
             </a>
           </router-link>
         </woot-dropdown-item>-->
      </woot-dropdown-menu>
    </div>
  </transition>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
import { mapGetters } from 'vuex';
import Auth from '../../../api/auth';
import WootDropdownItem from 'shared/components/ui/dropdown/DropdownItem';
import WootDropdownMenu from 'shared/components/ui/dropdown/DropdownMenu';
import AvailabilityStatus from 'dashboard/components/layout/AvailabilityStatus';

export default {
  components: {
    WootDropdownMenu,
    WootDropdownItem, AvailabilityStatus,
  },
  mixins: [clickaway],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      globalConfig: 'globalConfig/get',
      accountId: 'getCurrentAccountId',
      currentUserAutoOffline: 'getCurrentUserAutoOffline', /* to get currentUser */
    }),
    showChangeAccountOption() {
      if (this.globalConfig.createNewAccountFromDashboard) {
        return true;
      }

      const { accounts = [] } = this.currentUser;
      return accounts.length > 1;
    },
  },
  methods: {
    handleProfileSettingClick(e, navigate) {
      this.$emit('close');
      navigate(e);
    },
    handleKeyboardHelpClick() {
      this.$emit('key-shortcut-modal');
      this.$emit('close');
    },
    logout() {
      Auth.logout();
    },
    onClickAway() {
      if (this.show) this.$emit('close') ;
    },
    /* to update offline status */

    updateAutoOffline(autoOffline) {
       this.$store.dispatch('updateAutoOffline', {
           accountId: this.accountId,
           autoOffline,
       });
    },
  },
};
</script>
<style lang="scss" scoped>
.dropdown-pane {
  right: 3rem;
  top: 6.5rem;
  min-width: 16.8rem;
  z-index: var(--z-index-much-higher);
}

.title{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #737373;
  margin-bottom: 8px ;
}

/* style autoOffline */
.auto-offline--toggle {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: var(--space-smaller);
  margin: 0;

  .info-wrap {
    display: flex;
    align-items: center;
  }

  .info-icon {
    margin-top: -1px;
  }

  .auto-offline--switch {
    margin: -1px var(--space-micro) 0;
  }

  .auto-offline--text {
    margin: 0 var(--space-smaller);
    font-size: var(--font-size-mini);
    font-weight: var(--font-weight-medium);
    color: var(--s-700);
  }
}
</style>
