<template>
    <div class="date-picker">
        <date-picker
                :range="true"
                :confirm="true"
                :clearable="false"
                :editable="false"
                :confirm-text="confirmText"
                :placeholder="placeholder"
                :value="value"
                :open="openStatus"
                :lang="currentLanguage"
                @change="handleChange"
                @open="handleOpen"
                @close="handleClose"
                @blur="handleBlur"
        />
    </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/fr';

export default {
    components: { DatePicker },
    props: {
        confirmText: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: '',
        },
        value: {
            type: Array,
            default: () => [],
        },
        open: {
            type: Boolean,
            default: undefined
        },
    },
    data() {
        return {
            openStatus: false,
        };
    },
    watch: {
        open() {
            if (this.open === true) {
                this.openStatus = false;
            }
        }
    },
    computed: {
        currentLanguage() {
            return this.$root.$i18n.locale;
        }
    },
    methods: {
        handleChange(value) {
            this.$emit('change', value);
        },
        handleOpen(evt) {
            this.openStatus = true;
            this.$nextTick(() => {
                const calendar = document.querySelector('.mx-datepicker-main.mx-datepicker-popup');
                const parentElement = document.querySelector('.column.content-box');
                if (calendar && parentElement) {
                    if (calendar.parentElement !== parentElement) {
                        // If it's not already in the DOM, insert it
                        parentElement.insertBefore(calendar, parentElement.firstChild);
                    }
                }
            });
            /* const calendar = document.querySelector('.mx-datepicker-main.mx-datepicker-popup');

             if (calendar) {
                 // Add a reference to the calendar element
                 this.$refs.calendar = calendar;
           }*/
        },
        handleClose() {
            this.openStatus = false;
        },
        handleBlur(event) {
            const datePicker = this.$refs.datePicker;
            const calendar = this.$refs.calendar;
            // Check if the clicked element is inside the date-picker but not in the calendar
            if (datePicker && !datePicker.contains(event.target) && !(calendar && calendar.contains(event.target))) {
                this.openStatus = false;
            }
        },
    },
};
</script>

<style scoped lang="scss">
::v-deep .mx-input-wrapper input{
  height: 40px !important ;
  border: 1px solid #A8A8A8 !important ;
  border-radius: 8px!important;
}
</style>
