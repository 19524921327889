<template>
    <div class="shopping_info mb-3">
        <div class="d-flex basket_block">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.11968 8.715C9.09718 8.715 9.08218 8.715 9.05968 8.715C9.02218 8.7075 8.96968 8.7075 8.92468 8.715C6.74968 8.6475 5.10718 6.9375 5.10718 4.83C5.10718 2.685 6.85468 0.9375 8.99968 0.9375C11.1447 0.9375 12.8922 2.685 12.8922 4.83C12.8847 6.9375 11.2347 8.6475 9.14218 8.715C9.13468 8.715 9.12718 8.715 9.11968 8.715ZM8.99968 2.0625C7.47718 2.0625 6.23218 3.3075 6.23218 4.83C6.23218 6.33 7.40218 7.5375 8.89468 7.59C8.93218 7.5825 9.03718 7.5825 9.13468 7.59C10.6047 7.5225 11.7597 6.315 11.7672 4.83C11.7672 3.3075 10.5222 2.0625 8.99968 2.0625Z" fill="#483A9D"/>
                <path d="M9.12721 16.9125C7.65721 16.9125 6.17971 16.5375 5.06221 15.7875C4.01971 15.0975 3.44971 14.1525 3.44971 13.125C3.44971 12.0975 4.01971 11.145 5.06221 10.4475C7.31221 8.955 10.9572 8.955 13.1922 10.4475C14.2272 11.1375 14.8047 12.0825 14.8047 13.11C14.8047 14.1375 14.2347 15.09 13.1922 15.7875C12.0672 16.5375 10.5972 16.9125 9.12721 16.9125ZM5.68471 11.3925C4.96471 11.8725 4.57471 12.4875 4.57471 13.1325C4.57471 13.77 4.97221 14.385 5.68471 14.8575C7.55221 16.11 10.7022 16.11 12.5697 14.8575C13.2897 14.3775 13.6797 13.7625 13.6797 13.1175C13.6797 12.48 13.2822 11.865 12.5697 11.3925C10.7022 10.1475 7.55221 10.1475 5.68471 11.3925Z" fill="#483A9D"/>
            </svg>
            <span>3</span>
        </div>
        <svg class="mt--4" width="1" height="27" viewBox="0 0 1 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="0.5" y1="0.5" x2="0.499999" y2="26.1667" stroke="#A8A8A8" stroke-linecap="round"/>
        </svg>
        <div class="d-flex dislike_block">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.25 9.51953H1.5C1.48694 9.51953 1.47128 9.51433 1.45699 9.50004C1.4427 9.48575 1.4375 9.4701 1.4375 9.45703C1.4375 9.44397 1.4427 9.42831 1.45699 9.41402C1.47128 9.39973 1.48694 9.39453 1.5 9.39453H14.25C14.2631 9.39453 14.2787 9.39973 14.293 9.41402C14.3073 9.42831 14.3125 9.44397 14.3125 9.45703C14.3125 9.4701 14.3073 9.48575 14.293 9.50004C14.2787 9.51433 14.2631 9.51953 14.25 9.51953Z" stroke="black"/>
                <path d="M3.7508 4.99008L3.7508 4.99L3.74185 4.99055C3.08684 5.0308 2.44204 5.1189 2.02921 5.61667C1.82677 5.86076 1.71451 6.16212 1.64988 6.49847C1.58559 6.83313 1.5625 7.23513 1.5625 7.70961V12.9521C1.5625 13.4381 1.58171 13.8591 1.65208 14.2097C1.72362 14.5661 1.85602 14.8918 2.11374 15.1457C2.37067 15.3989 2.69899 15.5284 3.05828 15.5986C3.41283 15.6679 3.83973 15.6871 4.33502 15.6871H11.415C11.9045 15.6871 12.3255 15.6688 12.6753 15.6032C13.0288 15.537 13.3532 15.4149 13.6101 15.1743C13.8699 14.9312 14.007 14.6174 14.0832 14.2731C14.1578 13.936 14.1818 13.5327 14.1875 13.0708H14.1875V13.0646V7.70961C14.1875 7.22367 14.1683 6.80264 14.0979 6.452C14.0264 6.0956 13.894 5.76996 13.6363 5.51598C13.3793 5.26278 13.051 5.13327 12.6917 5.0631C12.3372 4.99385 11.9103 4.97461 11.415 4.97461H4.33502C4.12082 4.97461 3.92398 4.98255 3.7508 4.99008ZM11.415 15.8121H4.33502C3.07131 15.8121 2.40119 15.604 2.02323 15.2301C1.64688 14.8578 1.4375 14.199 1.4375 12.9521V7.70961C1.4375 6.71181 1.55721 6.05109 1.86379 5.62173C2.14521 5.22762 2.66276 4.92764 3.74421 4.86405C3.93784 4.85661 4.13002 4.84961 4.33502 4.84961H11.415C12.6787 4.84961 13.3488 5.05768 13.7268 5.43162C14.1031 5.80395 14.3125 6.4627 14.3125 7.70961V13.069C14.2975 14.2657 14.0841 14.894 13.7108 15.25C13.332 15.6113 12.6648 15.8121 11.415 15.8121Z" fill="black" stroke="black"/>
                <path d="M14.25 13.635C14.1075 13.635 13.965 13.5825 13.8675 13.485C13.755 13.38 13.6875 13.23 13.6875 13.0725V7.71C13.6875 5.79 13.3725 5.475 11.415 5.475H4.33502C4.13252 5.475 3.94502 5.4825 3.77252 5.49C3.62252 5.4975 3.47249 5.4375 3.35999 5.3325C3.24749 5.2275 3.1875 5.0775 3.1875 4.92C3.2175 2.475 4.04252 1.6875 6.58502 1.6875H13.665C16.2375 1.6875 17.0625 2.505 17.0625 5.0475V10.29C17.0625 12.3075 16.605 13.5 14.28 13.635C14.2725 13.635 14.2575 13.635 14.25 13.635ZM4.33502 4.35H11.415C13.9875 4.35 14.8125 5.1675 14.8125 7.71V12.45C15.6825 12.2925 15.9375 11.8425 15.9375 10.29V5.0475C15.9375 3.1275 15.6225 2.8125 13.665 2.8125H6.58502C4.87502 2.8125 4.43252 3.045 4.33502 4.35Z" fill="black"/>
                <path d="M5.2202 13.9199H3.93018C3.62268 13.9199 3.36768 13.6649 3.36768 13.3574C3.36768 13.0499 3.62268 12.7949 3.93018 12.7949H5.2202C5.5277 12.7949 5.7827 13.0499 5.7827 13.3574C5.7827 13.6649 5.5352 13.9199 5.2202 13.9199Z" fill="black"/>
                <path d="M9.41252 13.9199H6.83252C6.52502 13.9199 6.27002 13.6649 6.27002 13.3574C6.27002 13.0499 6.52502 12.7949 6.83252 12.7949H9.41252C9.72002 12.7949 9.97502 13.0499 9.97502 13.3574C9.97502 13.6649 9.72752 13.9199 9.41252 13.9199Z" fill="black"/>
            </svg>
            <span>02</span>
        </div>
        <svg class="mt--4" width="1" height="27" viewBox="0 0 1 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="0.5" y1="0.5" x2="0.499999" y2="26.1667" stroke="#A8A8A8" stroke-linecap="round"/>
        </svg>
        <div class="d-flex cash_block">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.39 4.2373L10.065 2.4373C9.76504 2.1373 9.09004 1.9873 8.64004 1.9873H5.79004C4.89004 1.9873 3.91504 2.6623 3.69004 3.5623L1.89004 9.0373C1.51504 10.0873 2.19004 10.9873 3.31504 10.9873H6.31504C6.76504 10.9873 7.14004 11.3623 7.06504 11.8873L6.69004 14.2873C6.54004 14.9623 6.99004 15.7123 7.66504 15.9373C8.26504 16.1623 9.01504 15.8623 9.31504 15.4123L12.39 10.8373" stroke="#FF2C52" stroke-width="1.5" stroke-miterlimit="10"/>
                <path d="M16.2151 4.2375V11.5875C16.2151 12.6375 15.7651 13.0125 14.7151 13.0125H13.9651C12.9151 13.0125 12.4651 12.6375 12.4651 11.5875V4.2375C12.4651 3.1875 12.9151 2.8125 13.9651 2.8125H14.7151C15.7651 2.8125 16.2151 3.1875 16.2151 4.2375Z" stroke="#FF2C52" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

            <span>03</span>
        </div>
        <svg class="mt--4" width="1" height="27" viewBox="0 0 1 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="0.5" y1="0.5" x2="0.499999" y2="26.1667" stroke="#A8A8A8" stroke-linecap="round"/>
        </svg>
        <div class="d-flex vip_block">
            <span>TRE</span>
        </div>
    </div>
</template>

<script>
export default {
    name: "BankingOperations"
}
</script>

<style scoped lang="scss">
  .shopping_info{
      background: #fff;
      border-radius: 8px;
      width: auto;
      height: 40px;
      display: flex;
      justify-content: space-evenly;
      padding: 10px 0;
      margin: 0 10px;
      .basket_block{
        justify-content: space-evenly;
        span{
          font-family: 'Inter';
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 15px;
          color: #8076ba;
          margin-left: 5px;
          margin-top: 3.4px;
        }
      }

      .dislike_block{
        justify-content: space-evenly;
        span{
          font-family: 'Inter';
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 15px;
          color: #000000;
          margin-left: 5px;
          margin-top: 3.4px;
        }
      }

      .cash_block{
        justify-content: space-evenly;
        span{
          font-family: 'Inter';
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 15px;
          color: #ec5757;
          margin-left: 5px;
          margin-top: 3.4px;
        }
      }

      .vip_block{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        color: #000000;
        margin-top: 3px;
      }

      .mt--4{
        margin-top: -4px;
      }
  }
</style>
