export const MACRO_ACTION_TYPES = [
  {
    key: 'assign_team',
    label: (language) => language === 'fr' ? 'Assigner une équipe' : 'Assign a team',
    inputType: 'search_select',
  },
  {
    key: 'assign_agent',
    label: (language) => language === 'fr' ? 'Assigner une équipe' : 'Assign an agent',
    inputType: 'search_select',
  },
  {
    key: 'add_label',
    label: (language) => language === 'fr' ? 'Ajouter un label' : 'Add a label',
    inputType: 'multi_select',
  },
  {
    key: 'remove_label',
    label: (language) => language === 'fr' ? 'Retirer un label' : 'Remove a label',
    inputType: 'multi_select',
  },
  {
    key: 'remove_assigned_team',
    label: (language) => language === 'fr' ? "Retirer l'équipe assignée" : 'Remove Assigned Team',
    inputType: null,
  },
  {
    key: 'send_email_transcript',
    label: (language) => language === 'fr' ? 'Envoyer une transcription par e-mail' : 'Send an email transcript',
    inputType: 'email',
  },
  {
    key: 'mute_conversation',
    label: (language) => language === 'fr' ? 'Muter la conversation' : 'Mute conversation',
    inputType: null,
  },
  {
    key: 'snooze_conversation',
    label: (language) => language === 'fr' ? 'Suspendre la conversation' : 'Snooze conversation',
    inputType: null,
  },
  {
    key: 'resolve_conversation',
    label: (language) => language === 'fr' ? 'Résoudre la conversation' : 'Resolve conversation',
    inputType: null,
  },
  {
    key: 'send_attachment',
    label: (language) => language === 'fr' ? 'Envoyer une pièce jointe' : 'Send Attachment',
    inputType: 'attachment',
  },
  {
    key: 'send_message',
    label: (language) => language === 'fr' ? 'Envoyer un message' : 'Send a message',
    inputType: 'textarea',
  },
  {
    key: 'add_private_note',
    label: (language) => language === 'fr' ? 'Ajouter une note privée' : 'Add a private note',
    inputType: 'textarea',
  },
  {
    key: 'change_priority',
    label: (language) => language === 'fr' ? 'Modifier la priorité' : 'Change Priority',
    inputType: 'search_select',
  },
];
