<template>
    <div class="column content-box">
        <!--    <woot-button
              color-scheme="primary"
              class-names="  "
              icon="add-circle"
              @click="openAddPopup()"
            >
              {{ $t('AUTOMATION.HEADER_BTN_TXT') }}
            </woot-button>-->
        <div class="row">
            <div class="columns with-right-space">
                <woot-button
                        color-scheme="primary"
                        class-names="  "
                        icon="add-circle"
                        @click="openAddPopup()"
                        class="float-right mt-2 mb-5"
                >
                    {{ $t('AUTOMATION.HEADER_BTN_TXT') }}
                </woot-button>
                <p
                        v-if="!uiFlags.isFetching && !records.length"
                        class="no-items-error-message"
                >
                    {{ $t('AUTOMATION.LIST.404') }}
                </p>
                <woot-loading-state
                        v-if="uiFlags.isFetching"
                        :message="$t('AUTOMATION.LOADING')"
                />

                <table v-if="!uiFlags.isFetching && records.length" class="table table-hover mb-4">
                    <tbody>
                    <tr>
                        <th
                                v-for="thHeader in $t('AUTOMATION.LIST.TABLE_HEADER')"
                                :key="thHeader"
                        >
                            {{ thHeader }}
                        </th>
                    </tr>
                    <tr v-for="(automation, index) in currentRecords" :key="index">
                        <td>{{ automation.name }}</td>
                        <td>{{ automation.description }}</td>
                        <td>
                            <woot-switch
                                    :value="automation.active"
                                    @input="toggleAutomation(automation, automation.active)"
                            />
                        </td>
                        <td>{{ readableTime(automation.created_on) }}</td>
                        <td class="actions">
                            <div class="actions-icons d-flex">
                                <div class="btn icon-btn icon-btn-success"   @click="openEditPopup(automation)">
                                    <svg width="17" height="17" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4.61666 16.2666C4.10832 16.2666 3.63332 16.0916 3.29166 15.7666C2.85832 15.3583 2.64999 14.7416 2.72499 14.075L3.03332 11.375C3.09166 10.8666 3.39999 10.1916 3.75832 9.82496L10.6 2.58329C12.3083 0.774959 14.0917 0.72496 15.9 2.43329C17.7083 4.14163 17.7583 5.92496 16.05 7.73329L9.20832 14.975C8.85832 15.35 8.20832 15.7 7.69999 15.7833L5.01666 16.2416C4.87499 16.25 4.74999 16.2666 4.61666 16.2666ZM13.275 2.42496C12.6333 2.42496 12.075 2.82496 11.5083 3.42496L4.66666 10.675C4.49999 10.85 4.30832 11.2666 4.27499 11.5083L3.96666 14.2083C3.93332 14.4833 3.99999 14.7083 4.14999 14.85C4.29999 14.9916 4.52499 15.0416 4.79999 15L7.48332 14.5416C7.72499 14.5 8.12499 14.2833 8.29166 14.1083L15.1333 6.86663C16.1667 5.76663 16.5417 4.74996 15.0333 3.33329C14.3667 2.69163 13.7917 2.42496 13.275 2.42496Z" fill="#656877"/>
                                        <path d="M14.4502 9.12504C14.4335 9.12504 14.4085 9.12504 14.3919 9.12504C11.7919 8.8667 9.7002 6.8917 9.3002 4.30837C9.2502 3.9667 9.48353 3.65004 9.8252 3.5917C10.1669 3.5417 10.4835 3.77504 10.5419 4.1167C10.8585 6.13337 12.4919 7.68337 14.5252 7.88337C14.8669 7.9167 15.1169 8.22504 15.0835 8.5667C15.0419 8.88337 14.7669 9.12504 14.4502 9.12504Z" fill="#656877"/>
                                        <path d="M17.5 18.9584H2.5C2.15833 18.9584 1.875 18.675 1.875 18.3334C1.875 17.9917 2.15833 17.7084 2.5 17.7084H17.5C17.8417 17.7084 18.125 17.9917 18.125 18.3334C18.125 18.675 17.8417 18.9584 17.5 18.9584Z" fill="#656877"/>
                                    </svg>
                                </div>
                                <div class="btn icon-btn icon-btn-danger " @click="openDeletePopup(automation, index)">
                                    <svg width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M21 6.73001C20.98 6.73001 20.95 6.73001 20.92 6.73001C15.63 6.20001 10.35 6.00001 5.12001 6.53001L3.08001 6.73001C2.66001 6.77001 2.29001 6.47001 2.25001 6.05001C2.21001 5.63001 2.51001 5.27001 2.92001 5.23001L4.96001 5.03001C10.28 4.49001 15.67 4.70001 21.07 5.23001C21.48 5.27001 21.78 5.64001 21.74 6.05001C21.71 6.44001 21.38 6.73001 21 6.73001Z" fill="#292D32"></path>
                                        <path d="M8.50001 5.72C8.46001 5.72 8.42001 5.72 8.37001 5.71C7.97001 5.64 7.69001 5.25 7.76001 4.85L7.98001 3.54C8.14001 2.58 8.36001 1.25 10.69 1.25H13.31C15.65 1.25 15.87 2.63 16.02 3.55L16.24 4.85C16.31 5.26 16.03 5.65 15.63 5.71C15.22 5.78 14.83 5.5 14.77 5.1L14.55 3.8C14.41 2.93 14.38 2.76 13.32 2.76H10.7C9.64001 2.76 9.62001 2.9 9.47001 3.79L9.24001 5.09C9.18001 5.46 8.86001 5.72 8.50001 5.72Z" fill="#292D32"></path>
                                        <path d="M15.21 22.75H8.78999C5.29999 22.75 5.15999 20.82 5.04999 19.26L4.39999 9.19C4.36999 8.78 4.68999 8.42 5.09999 8.39C5.51999 8.37 5.86999 8.68 5.89999 9.09L6.54999 19.16C6.65999 20.68 6.69999 21.25 8.78999 21.25H15.21C17.31 21.25 17.35 20.68 17.45 19.16L18.1 9.09C18.13 8.68 18.49 8.37 18.9 8.39C19.31 8.42 19.63 8.77 19.6 9.19L18.95 19.26C18.84 20.82 18.7 22.75 15.21 22.75Z" fill="#292D32"></path>
                                        <path d="M13.66 17.25H10.33C9.91999 17.25 9.57999 16.91 9.57999 16.5C9.57999 16.09 9.91999 15.75 10.33 15.75H13.66C14.07 15.75 14.41 16.09 14.41 16.5C14.41 16.91 14.07 17.25 13.66 17.25Z" fill="#292D32"></path>
                                        <path d="M14.5 13.25H9.5C9.09 13.25 8.75 12.91 8.75 12.5C8.75 12.09 9.09 11.75 9.5 11.75H14.5C14.91 11.75 15.25 12.09 15.25 12.5C15.25 12.91 14.91 13.25 14.5 13.25Z" fill="#292D32"></path>
                                    </svg>

                                </div>
                                <div @click="cloneAutomation(automation.id)" class="btn icon-btn icon-btn-clone"  >
                                    <svg width="17" height="17" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.24984 18.4584H5.74984C4.18311 18.4584 3.16329 18.1085 2.52735 17.4726C1.8914 16.8366 1.5415 15.8168 1.5415 14.2501V10.7501C1.5415 9.18336 1.8914 8.16354 2.52735 7.52759C3.16329 6.89165 4.18311 6.54175 5.74984 6.54175H9.24984C10.8166 6.54175 11.8364 6.89165 12.4723 7.52759C13.1083 8.16354 13.4582 9.18336 13.4582 10.7501V14.2501C13.4582 15.8168 13.1083 16.8366 12.4723 17.4726C11.8364 18.1085 10.8166 18.4584 9.24984 18.4584ZM5.74984 6.79175C4.42862 6.79175 3.38762 7.00936 2.69837 7.69861C2.00912 8.38786 1.7915 9.42887 1.7915 10.7501V14.2501C1.7915 15.5713 2.00912 16.6123 2.69837 17.3016C3.38762 17.9908 4.42862 18.2084 5.74984 18.2084H9.24984C10.5711 18.2084 11.6121 17.9908 12.3013 17.3016C12.9906 16.6123 13.2082 15.5713 13.2082 14.2501V10.7501C13.2082 9.42887 12.9906 8.38786 12.3013 7.69861C11.6121 7.00936 10.5711 6.79175 9.24984 6.79175H5.74984Z" fill="#656877" stroke="#656877"/>
                                        <path d="M13.4582 12.7084V13.2084H13.9582H14.2498C15.5711 13.2084 16.6121 12.9908 17.3013 12.3016C17.9906 11.6123 18.2082 10.5713 18.2082 9.25008V5.75008C18.2082 4.42887 17.9906 3.38786 17.3013 2.69861C16.6121 2.00936 15.5711 1.79175 14.2498 1.79175H10.7498C9.42862 1.79175 8.38762 2.00936 7.69837 2.69861C7.00912 3.38786 6.7915 4.42887 6.7915 5.75008V6.04175V6.54175H7.2915H9.24984C10.8166 6.54175 11.8364 6.89165 12.4723 7.52759C13.1083 8.16354 13.4582 9.18336 13.4582 10.7501V12.7084ZM14.2498 13.4584H13.3332C13.303 13.4584 13.2717 13.4461 13.2461 13.4205C13.2205 13.3948 13.2082 13.3636 13.2082 13.3334V10.7501C13.2082 9.42887 12.9906 8.38786 12.3013 7.69861C11.6121 7.00936 10.5711 6.79175 9.24984 6.79175H6.6665C6.63636 6.79175 6.60508 6.77946 6.57943 6.75382C6.55379 6.72818 6.5415 6.6969 6.5415 6.66675V5.75008C6.5415 4.18336 6.8914 3.16354 7.52735 2.52759C8.16329 1.89165 9.18311 1.54175 10.7498 1.54175H14.2498C15.8166 1.54175 16.8364 1.89165 17.4723 2.52759C18.1083 3.16354 18.4582 4.18336 18.4582 5.75008V9.25008C18.4582 10.8168 18.1083 11.8366 17.4723 12.4726C16.8364 13.1085 15.8166 13.4584 14.2498 13.4584Z" fill="#656877" stroke="#656877"/>
                                    </svg>
                                </div>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

        </div>
        <woot-modal
                :show.sync="showAddPopup"
                size="medium"
                :on-close="hideAddPopup"
        >
            <add-automation-rule
                    v-if="showAddPopup"
                    :on-close="hideAddPopup"
                    @saveAutomation="submitAutomation"
            />
        </woot-modal>

        <woot-delete-modal
                :show.sync="showDeleteConfirmationPopup"
                :on-close="closeDeletePopup"
                :on-confirm="confirmDeletion"
                :title="$t('LABEL_MGMT.DELETE.CONFIRM.TITLE')"
                :message="$t('AUTOMATION.DELETE.CONFIRM.MESSAGE')"
                :message-value="deleteMessage"
                :confirm-text="deleteConfirmText"
                :reject-text="deleteRejectText"
        />

        <woot-modal
                :show.sync="showEditPopup"
                size="medium"
                :on-close="hideEditPopup"
        >
            <edit-automation-rule
                    v-if="showEditPopup"
                    :on-close="hideEditPopup"
                    :selected-response="selectedResponse"
                    @saveAutomation="submitAutomation"
            />
        </woot-modal>
        <woot-confirm-modal
                ref="confirmDialog"
                :title="toggleModalTitle"
                :description="toggleModalDescription"
        />
        <table-footer
            :current-page="currentPage"
            :total-count="total === 0 ? records.length : total"
            :page-size="size"
             @page-change="onPageChange"
        />
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import AddAutomationRule from './AddAutomationRule.vue';
import EditAutomationRule from './EditAutomationRule.vue';
import alertMixin from 'shared/mixins/alertMixin';
import timeMixin from 'dashboard/mixins/time';
import TableFooter from 'dashboard/components/widgets/TableFooter';

export default {
  components: {
    AddAutomationRule,
    EditAutomationRule,
    TableFooter,
  },
  mixins: [alertMixin, timeMixin],
  data() {
    return {
      loading: {},
      showAddPopup: false,
      showEditPopup: false,
      showDeleteConfirmationPopup: false,
      selectedResponse: {},
      toggleModalTitle: this.$t('AUTOMATION.TOGGLE.ACTIVATION_TITLE'),
      toggleModalDescription: this.$t(
        'AUTOMATION.TOGGLE.ACTIVATION_DESCRIPTION'
      ),
      size: 5,
      currentPage: 1,
      total: 0,
    };
  },
  computed: {
    ...mapGetters({
      records: ['automations/getAutomations'],
      uiFlags: 'automations/getUIFlags',
    }),
    // Delete Modal
    deleteConfirmText() {
      return `${this.$t('AUTOMATION.DELETE.CONFIRM.YES')} ${
        this.selectedResponse.name
      }`;
    },
    deleteRejectText() {
      return `${this.$t('AUTOMATION.DELETE.CONFIRM.NO')} ${
        this.selectedResponse.name
      }`;
    },
    deleteMessage() {
      return ` ${this.selectedResponse.name}?`;
    },
    currentRecords() {
          return this.records.slice(
              (this.currentPage - 1) * this.size,
              ((this.currentPage - 1) * this.size) + this.size
          )
    }
  },
  mounted() {
    this.$store.dispatch('inboxes/get');
    this.$store.dispatch('agents/get');
    this.$store.dispatch('contacts/get');
    this.$store.dispatch('teams/get');
    this.$store.dispatch('labels/get');
    this.$store.dispatch('campaigns/get');
    this.$store.dispatch('automations/get');
  },
  methods: {
    openAddPopup() {
      this.showAddPopup = true;
    },
    hideAddPopup() {
      this.showAddPopup = false;
    },
    openEditPopup(response) {
      this.selectedResponse = response;
      this.showEditPopup = true;
    },
    hideEditPopup() {
      this.showEditPopup = false;
    },
    openDeletePopup(response) {
      this.showDeleteConfirmationPopup = true;
      this.selectedResponse = response;
    },
    closeDeletePopup() {
      this.showDeleteConfirmationPopup = false;
    },
    confirmDeletion() {
      this.loading[this.selectedResponse.id] = true;
      this.closeDeletePopup();
      this.deleteAutomation(this.selectedResponse.id);
    },
    async deleteAutomation(id) {
      try {
        await this.$store.dispatch('automations/delete', id);
        this.onChangeTotal();
        this.showAlert(this.$t('AUTOMATION.DELETE.API.SUCCESS_MESSAGE'));
        this.loading[this.selectedResponse.id] = false;
      } catch (error) {
        this.showAlert(this.$t('AUTOMATION.DELETE.API.ERROR_MESSAGE'));
      }
    },
    async cloneAutomation(id) {
      try {
        await this.$store.dispatch('automations/clone', id);
        this.showAlert(this.$t('AUTOMATION.CLONE.API.SUCCESS_MESSAGE'));
        this.$store.dispatch('automations/get');
        this.loading[this.selectedResponse.id] = false;
      } catch (error) {
        this.showAlert(this.$t('AUTOMATION.CLONE.API.ERROR_MESSAGE'));
      }
    },
    async submitAutomation(payload, mode) {
      try {
        const action =
          mode === 'edit' ? 'automations/update' : 'automations/create';
        const successMessage =
          mode === 'edit'
            ? this.$t('AUTOMATION.EDIT.API.SUCCESS_MESSAGE')
            : this.$t('AUTOMATION.ADD.API.SUCCESS_MESSAGE');
        await this.$store.dispatch(action, payload);
        this.showAlert(this.$t(successMessage));
        this.hideAddPopup();
        this.hideEditPopup();
      } catch (error) {
        const errorMessage =
          mode === 'edit'
            ? this.$t('AUTOMATION.EDIT.API.ERROR_MESSAGE')
            : this.$t('AUTOMATION.ADD.API.ERROR_MESSAGE');
        this.showAlert(errorMessage);
      }
    },
    async toggleAutomation(automation, status) {
      try {
        this.toggleModalTitle = status
          ? this.$t('AUTOMATION.TOGGLE.DEACTIVATION_TITLE')
          : this.$t('AUTOMATION.TOGGLE.ACTIVATION_TITLE');
        this.toggleModalDescription = status
          ? this.$t('AUTOMATION.TOGGLE.DEACTIVATION_DESCRIPTION', {
              automationName: automation.name,
            })
          : this.$t('AUTOMATION.TOGGLE.ACTIVATION_DESCRIPTION', {
              automationName: automation.name,
            });
        // Check if user confirms to proceed
        const ok = await this.$refs.confirmDialog.showConfirmation();
        if (ok) {
          await await this.$store.dispatch('automations/update', {
            id: automation.id,
            active: !status,
          });
          const message = status
            ? this.$t('AUTOMATION.TOGGLE.DEACTIVATION_SUCCESFUL')
            : this.$t('AUTOMATION.TOGGLE.ACTIVATION_SUCCESFUL');
          this.showAlert(message);
        }
      } catch (error) {
        this.showAlert(this.$t('AUTOMATION.EDIT.API.ERROR_MESSAGE'));
      }
    },
    readableTime(date) {
      return this.messageStamp(new Date(date), 'LLL d, h:mm a');
    },
    onPageChange(page) {
          this.currentPage = page;
    },
    onChangeTotal() {
          const prevCount = this.total;
          const automations = this.$store.getters['automations/getAutomations'];
          this.total = automations.length ;
          /* return to the previous page when the last element of the current page is deleted */
          if (this.total < prevCount &&  this.total % 5 === 0) {
              this.currentPage = this.currentPage - 1 ;
          }
    }
  },
};
</script>

<style scoped>
.automation__status-checkbox {
  margin: 0;
}
</style>
