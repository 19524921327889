<template>
    <div class="dropdown-wrap">
        <div v-if="!addAgent && !editAgent && !isBot && !isAttribute && !isContract && options.length && !isConv" class="search-wrap">
            <input
                    ref="searchbar"
                    v-model="search"
                    type="text"
                    class="search-input"
                    autofocus="true"
                    :placeholder="inputPlaceholder"
            />
        </div>
        <div class="list-scroll-container" :class="{'scroll-dropdown': isConv}">
            <div class="multiselect-dropdown--list" :class="{'height-dropdown': isConv}">
                <woot-dropdown-menu>
                    <woot-dropdown-item
                            v-for="option in filteredOptions"
                            :key="option.id"
                            :style="{
                    marginLeft: option.hasOwnProperty('attributes') ? '-2rem' : '0rem'
            }"
                    >
                        <woot-button
                                class="multiselect-dropdown--item"
                                :variant="isActive(option) ? 'hollow' : 'clear'"
                                color-scheme="secondary"
                                :class="{
                active: isActive(option), 'height': isConv
              }"
                                :style="{ cursor: option.hasOwnProperty('attributes') ? 'auto' : 'pointer' }"
                                :disabled="option.hasOwnProperty('attributes')"
                                @click.prevent="() => onclick(option)"
                        >
                            <div class="user-wrap">
                                <Thumbnail
                                        v-if="hasThumbnail"
                                        :src="option.thumbnail"
                                        size="24px"
                                        :username="option.name"
                                        :status="option.availability_status"
                                        has-border
                                />
                                <inbox-dropdown-item
                                        v-if="newConv"
                                        :name="option.name"
                                        :inbox-identifier="computedInboxSource(option)"
                                        :channel-type="option.channel_type"
                                />
                                <div v-if="!newConv" class="name-wrap">
                  <span
                          class="name text-truncate text-block-title"
                          :title="option.name"
                          :class="{'font-size': isConv}"
                          :style="{
                    fontSize: option.hasOwnProperty('attributes') ? '1.6rem' : '1.4rem'
                    }"
                  >
                    {{ optionName(option) }}
                  </span>
                                    <fluent-icon v-if="isActive(option)" icon="checkmark" />
                                </div>
                            </div>
                        </woot-button>
                    </woot-dropdown-item>
                </woot-dropdown-menu>
                <h4 v-if="noResult" class="no-result text-truncate text-block-title">
                    {{ noSearchResult }}
                </h4>
            </div>
        </div>
    </div>
</template>

<script>
import WootDropdownItem from 'shared/components/ui/dropdown/DropdownItem.vue';
import WootDropdownMenu from 'shared/components/ui/dropdown/DropdownMenu.vue';
import Thumbnail from 'dashboard/components/widgets/Thumbnail.vue';
import InboxDropdownItem from 'dashboard/components/widgets/InboxDropdownItem';
import { getInboxSource } from 'dashboard/helper/inbox' ;

export default {
    components: {
        WootDropdownItem,
        WootDropdownMenu,
        Thumbnail,
        InboxDropdownItem,
    },

    props: {
        options: {
            type: [Array, Object],
            default: () => [],
        },
        selectedItems: {
            type: [Array, Object],
            default: () => [],
        },
        hasThumbnail: {
            type: Boolean,
            default: true,
        },
        inputPlaceholder: {
            type: String,
            default: 'Search',
        },
        noSearchResult: {
            type: String,
            default: 'No results found',
        },
        isCountry: {
            type: Boolean,
            default: false,
        },
        isInbox: {
            type: Boolean,
            default: false,
        },
        isAgent: {
            type: Boolean,
            default: false,
        },
        newConv: {
            type: Boolean,
            default: false,
        },
        isTeam: {
            type: Boolean,
            default: false,
        },
        addAgent: {
            type: Boolean,
            default: false,
        },
        editAgent: {
            type: Boolean,
            default: false,
        },
        isAttribute: {
            type: Boolean,
            default: false,
        },
        isBot: {
            type: Boolean,
            default: false,
        },
        isLang: {
            type: Boolean,
            default: false,
        },
        isTime: {
            type: Boolean,
            default: false,
        },
        isConvFilter: {
            type: Boolean,
            default: false,
        },
        isOperatorFilter: {
            type: Boolean,
            default: false,
        },
        isAnswerFilter: {
            type: Boolean,
            default: false,
        },
        isContract: {
            type: Boolean,
            default: false,
        },
        isFilterContract: {
            type: Boolean,
            default: false,
        },
        isClaim: {
            type: Boolean,
            default: false,
        },
        isConv: {
            type: Boolean,
            default: false,
        }
    },

    data() {
        return {
            search: '',
        };
    },

    computed: {
        filteredOptions() {
            if (this.isInbox) {
                return this.options.filter(option => {
                    return option.name.toLowerCase().includes(this.search.toLowerCase()) ;
                });
            }
            else if (this.editAgent) {
                return this.options.filter(option => {
                    return option.label.toLowerCase().includes(this.search.toLowerCase());
                });
            }
            else if (this.isAttribute) {
                return this.options.filter(option => {
                    return option.option.toLowerCase().includes(this.search.toLowerCase());
                });
            }
            else if (this.isConvFilter) {
                const attributeNames = [];

                for (let i = 0; i < Object.keys(this.options).length; i++) {
                    const option = this.options[i];
                    attributeNames.push(option);
                    // Path of each attribute
                    for (let j = 0; j < Object.keys(option.attributes).length; j++) {
                        const attribute = option.attributes[j];
                        // Check if the attribute name contains the search term
                        if (attribute.name.toLowerCase().includes(this.search.toLowerCase())) {
                            attributeNames.push(attribute);
                        }
                    }
                }
                return attributeNames;
            }
            else if (this.isOperatorFilter) {
                return this.options.filter(option => {
                    return this.$t(`FILTER.OPERATOR_LABELS.${option.value}`).toLowerCase().includes(this.search.toLowerCase());
                });
            }
            else if (this.isClaim) {
                return this.options.filter(option => {
                    return option.value.toLowerCase().includes(this.search.toLowerCase());
                });
            }
            else if (this.isConv) {
                return this.options;
            }
            else {
                return this.options.filter(option => {
                    return option.name.toLowerCase().includes(this.search.toLowerCase());
                });
            }
        },
        noResult() {
            return this.filteredOptions.length === 0 && this.search !== '';
        },
    },

    mounted() {
        if (!this.addAgent && !this.editAgent && !this.isContract && this.options.length && !this.isClaim && !this.isConv) {
            this.focusInput()  ;
        }
    },

    methods: {
        optionName(option) {
            if (this.isCountry) {
                return `${option.name} (${option.id})`;
            }
            else if (this.isInbox) {
                return `${option.name}`;
            }
            else if (this.editAgent) {
                return `${option.label}`;
            }
            else if (this.isAttribute) {
                return `${option.option}`;
            }
            else if (this.isOperatorFilter) {
                return this.$t(`FILTER.OPERATOR_LABELS.${option.value}`);
            }
            else if(this.isClaim) {
                return `${option.value}` ;
            }
            else {
                return `${option.name}`;
            }
        },
        onclick(option) {
            this.$emit('click', option);
        },
        focusInput() {
            this.$refs.searchbar.focus();
        },
        isActive(option) {
            if (Array.isArray(this.selectedItems)) {
                return this.selectedItems.some(item => item && option.id === item.id);
            }
            else {
                if (this.isCountry || this.isAgent || this.isTeam || this.addAgent || this.isAttribute || this.isAnswerFilter || this.isConv) {
                    if (this.selectedItems.id === option.id) {
                        return true;
                    }
                    else {
                        return false;
                    }
                }
                else if (this.isInbox) {
                    if (this.selectedItems.id === option.id) {
                        return true;
                    }
                    else {
                        return false;
                    }
                }
                else if (this.editAgent || this.isTime || this.isOperatorFilter || this.isContract || this.isClaim) {
                    if (this.selectedItems.value === option.value) {
                        return true;
                    }
                    else {
                        return false;
                    }
                }
                else if (this.isLang) {
                    if (this.selectedItems.iso_639_1_code === option.iso_639_1_code) {
                        return true;
                    }
                    else {
                        return false;
                    }
                }
                else if (this.isConvFilter) {
                    if (this.selectedItems.key === option.key) {
                        return true;
                    }
                    else {
                        return false;
                    }
                }
                else if (this.isFilterContract) {
                    if (this.selectedItems.name === option.name) {
                        return true;
                    }
                    else {
                        return false;
                    }
                }
            }
        },
        computedInboxSource(inbox) {
            if (!inbox.channel_type) return '';
            const classByType = getInboxSource(
                inbox.channel_type,
                inbox.phone_number,
                inbox
            );
            return classByType;
        },
    },
};
</script>

<style lang="scss" scoped>
.dropdown-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: 20rem;
}
.scroll-dropdown {
  overflow: hidden !important;
}
.height-dropdown {
  max-height: none !important;
}

.search-wrap {
  margin-bottom: var(--space-small);
  flex: 0 0 auto;
  max-height: var(--space-large);
}

.search-input {
  margin: 0;
  width: 100%;
  border: 1px solid transparent;
  height: var(--space-large);
  font-size: var(--font-size-small);
  padding: var(--space-small);
  background-color: var(--color-background);

  &:focus {
    border: 1px solid var(--w-500);
  }
}

.list-scroll-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1 1 auto;
  overflow: auto;
}

.multiselect-dropdown--list {
  width: 100%;
  max-height: 16rem;
}

.multiselect-dropdown--item {
  justify-content: space-between;
  width: 100%;

  &.active {
    background: var(--s-25);
    border-color: var(--s-50);
    font-weight: var(--font-weight-medium);
  }

  &:focus {
    background-color: var(--color-background-light);
  }

  &:hover {
    color: var(--s-800);
    background-color: var(--color-background);
  }
}

.user-wrap {
  display: flex;
  align-items: center;
}

.name-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 0;
  width: 100%;
  align-items: center;
}

.name {
  line-height: var(--space-normal);
  margin: 0 var(--space-small);
}

.icon {
  margin-left: var(--space-smaller);
}

.no-result {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: var(--space-small) var(--space-one);
}
.height {
  height: 3rem ;
}
.font-size {
  font-size: var(--font-size-mini) !important;
}
</style>
