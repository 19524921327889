<template>
    <div class="conversation-details-wrap" :class="{ 'with-border-left': !isOnExpandedLayout }">
        <conversation-header v-if="currentChat.id" :chat="currentChat" :is-contact-panel-open="isContactPanelOpen"
                             :show-back-button="isOnExpandedLayout" @contact-panel-toggle="onToggleContactPanel" />
        <woot-tabs v-if="dashboardApps.length && currentChat.id" :index="activeIndex" class="dashboard-app--tabs"
                   @change="onDashboardAppTabChange">
            <woot-tabs-item v-for="tab in dashboardAppTabs" :key="tab.key" :name="tab.name" :show-badge="false" />
        </woot-tabs>
        <div v-if="!activeIndex" class="messages-and-sidebar">
            <messages-view v-if="currentChat.id" :inbox-id="inboxId" :is-contact-panel-open="isContactPanelOpen"
                           @contact-panel-toggle="onToggleContactPanel" />
            <empty-state v-else :is-on-expanded-layout="isOnExpandedLayout" />
            <div v-show="showContactPanel" class="conversation-sidebar-wrap">
                <contact-panel v-if="showContactPanel" :conversation-id="currentChat.id" :inbox-id="currentChat.inbox_id"
                               :on-toggle="onToggleContactPanel" :layout="layout" :id="currentID">
                </contact-panel>
            </div>
<!--            <div v-show="showContactPanel" class="group-btn">
                <div class="bar-option" :class="{ barActive: layout === 'gen-info' }">
                    <button class="info-gen" @click="layout = 'gen-info'">
                      <span style="white-space: nowrap; font-size: larger;" >
                        {{ $t('CONVERSATION.INSURANCE.GENERAL_INFO') }}
                      </span>
                    </button>
                </div>
&lt;!&ndash;                <div class="bar-option" :class="{ barActive: layout === 'Fidelity' }">
                    <button class="info-gen" @click="layout = 'Fidelity'">
                      <span style="font-size: larger;">
                        {{ $t('CONVERSATION.INSURANCE.CONTRACTS') }}
                      </span>
                    </button>
                </div>
                <div class="bar-option" :class="{ barActive: layout === 'Orders' }">
                    <button class="info-gen" @click="layout = 'Orders'">
                      <span style="font-size: larger;">
                        {{ $t('CONVERSATION.INSURANCE.SINISTERS') }}
                      </span>
                    </button>
                </div>
                <div class="bar-option" :class="{ barActive: layout === 'Complaints' }">
                    <button class="info-gen" @click="layout = 'Complaints'">
                      <span style="font-size: larger;">
                        {{ $t('CONVERSATION.INSURANCE.COMPLAINTS') }}
                      </span>
                    </button>
                </div>&ndash;&gt;
            </div>-->
        </div>
        <dashboard-app-frame v-else :key="currentChat.id" :config="dashboardApps[activeIndex - 1].content"
                             :current-chat="currentChat" />
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import ContactPanel from 'dashboard/routes/dashboard/conversation/ContactPanel';
import ConversationHeader from './ConversationHeader';
import DashboardAppFrame from '../DashboardApp/Frame.vue';
import EmptyState from './EmptyState';
import MessagesView from './MessagesView';
import UserBanner from "./WhatsappTemplates/UserBanner";

import router from "../../../routes";

export default {
    components: {
        UserBanner,
        ContactPanel,
        ConversationHeader,
        DashboardAppFrame,
        EmptyState,
        MessagesView,
    },
    props: {
        inboxId: {
            type: [Number, String],
            default: '',
            required: false,
        },
        isContactPanelOpen: {
            type: Boolean,
            default: true,
        },
        isOnExpandedLayout: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            activeIndex: 0,
            layout: "gen-info",
            currentID: 0,
        };
    },
    computed: {
        ...mapGetters({
            currentChat: 'getSelectedChat',
            dashboardApps: 'dashboardApps/getRecords',
        }),
        dashboardAppTabs() {
            return [
                {
                    key: 'messages',
                    name: this.$t('CONVERSATION.DASHBOARD_APP_TAB_MESSAGES'),
                },
                ...this.dashboardApps.map(dashboardApp => ({
                    key: `dashboard-${dashboardApp.id}`,
                    name: dashboardApp.title,
                })),
            ];
        },
        showContactPanel() {
            return this.isContactPanelOpen && this.currentChat.id;
        },

    },
    watch: {
        'currentChat.inbox_id'(inboxId) {
            if (inboxId) {
                this.$store.dispatch('inboxAssignableAgents/fetch', [inboxId]);
            }
        },
        'currentChat.id'() {
            this.fetchLabels();
            this.activeIndex = 0  ;
        },
    },
    mounted() {
        this.fetchLabels();
        this.$store.dispatch('dashboardApps/get');
    },
    methods: {
        fetchLabels() {
            if (!this.currentChat.id) {
                return;
            }
            this.$store.dispatch('conversationLabels/get', this.currentChat.id);
        },
        onToggleContactPanel() {
            this.$emit('contact-panel-toggle');
        },
        onDashboardAppTabChange(index) {
            this.activeIndex = index;
        },
        toggleActions() {
            if (this.currentID < this.images.length - 1) {
                this.currentID += 1
            } else {
                this.currentID = 0
            }
        },
        // openAddPopup() {
        //     this.showAddPopup = true;
        // },
        // hideAddPopup() {
        //     this.showAddPopup = false;
        // },
        // openValidatePopup(){
        //     this.showValidatePopup = true;
        //     console.log(this.showValidatePopup);
        // },
        // hideValidatePopup() {
        //   this.showValidatePopup = false;
        //   console.log(this.showValidatePopup);
        // },

    },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/woot';

.conversation-details-wrap {
  display: flex;
  flex-direction: column;
  min-width: 0;
  width: 100%;
  background: var(--color-background-light);

  &.with-border-left {
    border-left: 1px solid var(--color-border);
  }
}

.dashboard-app--tabs {
  background: var(--white);
  margin-top: -1px;
  min-height: var(--dashboard-app-tabs-height);
}

.messages-and-sidebar {
  display: flex;
  background: var(--color-background-light);
  margin: 0;
  height: 100%;
  min-height: 0;
}

.group-btn {
  margin-top: 15px!important;
  display: flex;
  flex-direction: column;
  background: #ffff;
  width: 3%;
  margin-bottom: 12px!important;

  .bar-option {
    height: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0px 8px 8px 0px !important;
  }
  .info-gen {
    transform: rotate(90deg);
    cursor: pointer;
    color: #A8A8A8 ;
  }
  .barActive {
    background-color: #0081c9; /* #483a9d */
    color: #FFFF;
    cursor: default;
    .info-gen {
      color: #FFFF !important;
    }
  }
}

.conversation-sidebar-wrap {
  //height: 1000px;
  flex: 0 0;
  overflow: hidden;
  overflow: auto;
  background: white;
  flex-basis: 28rem;
  border-radius: 8px 0px 8px 8px !important;


  @include breakpoint(large up) {
    flex-basis: 30em;
  }

  @include breakpoint(xlarge up) {
    flex-basis: 31em;
  }

  @include breakpoint(xxlarge up) {
    flex-basis: 33rem;
  }

  @include breakpoint(xxxlarge up) {
    flex-basis: 40rem;
  }

  &::v-deep .contact--panel {
    width: 100%;
    height: 100%;
    max-width: 100%;
    border-radius: 8px 0px 8px 8px;
  }
}

//Responsive mode
@media (max-width: 750px) {
  .btn-group {
    left: 97.21%;
    top: 14.6%;
    z-index: 1;
  }
}

@media (min-width: 1200px) {
  .btn-group {
    left: 97.6%;
    top: 11.5%;
    z-index: 1;
  }
}

@media (min-width: 1400px) {
  .btn-group {
    left: 97.69%;
    top: 11.19%;
    z-index: 1;
  }
}

@media (min-width: 1870px) {
  .btn-group {
    left: 98.14%;
    top: 8.8%;
    z-index: 1;
  }
}
</style>
