<template>
    <div class="medium-3 bg-white contact--panel">
        <transition name="slide-fade">
            <client-informations
                    v-if="layout === 'gen-info' && isAssurance"
                    class="slide"
                    :contact="contact"
                    :key="currentID"
                    :client-address="clientAddress"
                    :client-email="clientEmail"
                    :client-phone-number="clientPhoneNumber"
                    :client-gender="clientGender"
                    :client-age="clientAge"
                    :edit-url="clientEditUrl"
                    :client-contracts-count="clientContractsCount"
                    :client-sinisters-count="clientSinistersCount"
                    :client-claims-count="clientClaimsCount"
                    :client-automobile-contracts-count="clientAutomobileContractsCount"
                    :client-incendie-contracts-count="clientIncendieContractsCount"
                    :client-transport-contracts-count="clientTransportContractsCount"
                    :client-var-risks-contracts-count="clientVarRisksContractsCount"
                    :client-health-contracts-count="clientHealthContractsCount"
                    :client-life-contracts-count="clientLifeContractsCount"
                    :client-special-risks-contracts-count="clientSpecialRisksContractsCount"
                    :client-rc-contracts-count="clientRcContractsCount"
            >
            </client-informations>
          <generale-infos
            v-if="layout === 'gen-info' && isBanking"
            :contact="contact"
            class="slide"
            :key="currentID"
          >
          </generale-infos>
            <Contracts
                    v-if="layout === 'Fidelity'"
                    class="slide"
                    :key="currentID"
                    :client-all-contracts="clientAllContracts"
            >
            </Contracts>
            <Sinisters
                    v-if="layout === 'Orders'"
                    class="slide"
                    :key="currentID"
                    :client-all-sinisters="clientAllSinisters"
                    :customerEmail="contact.email"
            >
            </Sinisters>
            <AssuranceComplaints
                    v-if="layout === 'Complaints'"
                    class="slide"
                    :key="currentID"
                    :client-all-claims="clientAllClaims"
            >
            </AssuranceComplaints>
        </transition>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import GeneralInfoVue from "./GeneralInfoContent";
import OrdersContent from "./OrdersContent";
import FidelityContentVue from "./FidelityContent";
import Sinisters from "../../../components/widgets/conversation/AssuranceBlock/Sinisters"
import Contracts from "../../../components/widgets/conversation/AssuranceBlock/Contracts"
import AssuranceComplaints from "../../../components/widgets/conversation/AssuranceBlock/AssuranceComplaints"
import ComplaintsContent from "./ComplaintsContent"
import ClientInformations from "../../../components/widgets/conversation/RetailBlocks/ClientInformations";
import OrdersBlock from "../../../components/widgets/conversation/RetailBlocks/OrdersBlock";
import GeneraleInfos from "../../../components/widgets/conversation/BankingBlock/GeneraleInfos.vue";


export default {
    components: {
        OrdersBlock,
        ClientInformations,
        ComplaintsContent,
        FidelityContentVue,
        OrdersContent,
        GeneralInfoVue,
        Contracts,
        Sinisters,
        AssuranceComplaints,
        GeneraleInfos
    },
    props: {
        conversationId: {
            type: [Number, String],
            required: true,
        },
        inboxId: {
            type: Number,
            default: undefined,
        },
        onToggle: {
            type: Function,
            default: () => { },
        },
        layout: String,
        currentID: Number
    },
    data() {
        return {
            dragEnabled: true,
            conversationSidebarItems: [],
            dragging: false,
            customerData: {
                serialNumber: 0,
                email: '',
                address: '',
                phoneNumber: 0,
                gender: '',
                age: 0,
                editUrl: '',
                currentContractsCount: 0,
                currentSinistersCount: 0,
                currentClaimsCount: 0,
            },
            currentContractsCount: {
                automobile: 0,
                incendie: 0,
                transport: 0,
                variousRisks: 0,
                health: 0,
                life: 0,
                specialRisks: 0,
                rc: 0
            },
            allContracts: [],
            allSinisters: [],
            allClaims: [],
            isAssurance: false,
            isBanking: true,
        };
    },
    computed: {
        ...mapGetters({
            currentChat: 'getSelectedChat',
            currentUser: 'getCurrentUser',
            uiFlags: 'inboxAssignableAgents/getUIFlags',
        }),
        conversationAdditionalAttributes() {
            return this.currentConversationMetaData.additional_attributes || {};
        },
        channelType() {
            return this.currentChat.meta?.channel;
        },
        contact() {
            return this.$store.getters['contacts/getContact'](this.contactId);
        },
        contactAdditionalAttributes() {
            return this.contact.additional_attributes || {};
        },
        contactId() {
            return this.currentChat.meta?.sender?.id;
        },
        currentConversationMetaData() {
            return this.$store.getters[
                'conversationMetadata/getConversationMetadata'
                ](this.conversationId);
        },
        hasContactAttributes() {
            const { custom_attributes: customAttributes } = this.contact;
            return customAttributes && Object.keys(customAttributes).length;
        },
        clientAddress() {
            return this.customerData.address ;
        },
        clientEmail() {
            return this.customerData.email;
        },
        clientPhoneNumber() {
            return this.customerData.phoneNumber;
        },
        clientGender() {
            return this.customerData.gender;
        },
        clientAge() {
            return this.customerData.age;
        },
        clientEditUrl() {
            return this.customerData.editUrl;
        },
        clientContractsCount() {
            return this.customerData.currentContractsCount;
        },
        clientSinistersCount() {
            return this.customerData.currentSinistersCount;
        },
        clientClaimsCount() {
            return this.customerData.currentClaimsCount;
        },
        clientAutomobileContractsCount() {
            return this.currentContractsCount.automobile;
        },
        clientIncendieContractsCount() {
            return this.currentContractsCount.incendie;
        },
        clientTransportContractsCount() {
            return this.currentContractsCount.transport;
        },
        clientVarRisksContractsCount() {
            return this.currentContractsCount.variousRisks;
        },
        clientHealthContractsCount() {
            return this.currentContractsCount.health;
        },
        clientLifeContractsCount() {
            return this.currentContractsCount.life;
        },
        clientSpecialRisksContractsCount() {
            return this.currentContractsCount.specialRisks;
        },
        clientRcContractsCount() {
            return this.currentContractsCount.rc;
        },
        /* to return all the current contracts */
        clientAllContracts() {
            return this.allContracts;
        },
        /* to return all the current sinisters */
        clientAllSinisters() {
            return this.allSinisters;
        },
        /* to return all the current claims */
        clientAllClaims() {
            return this.allClaims;
        }
    },
    watch: {
        conversationId(newConversationId, prevConversationId) {
            if (newConversationId && newConversationId !== prevConversationId) {
                this.getContactDetails();
            }
        },
        contactId() {
            this.getContactDetails();
        },
    },
    mounted() {
        this.conversationSidebarItems = this.conversationSidebarItemsOrder;
        this.getContactDetails();
        this.$store.dispatch('attributes/get', 0);
        /*this.getIdentityByCin(this.contact.cin, this.contact.birth_date);*/
        /*this.getClientClaims(this.contact.cin);*/
        /*this.getRedirectUrl(this.contact.cin);*/
    },
    methods: {
        onPanelToggle() {
            this.onToggle();
        },
        getContactDetails() {
            if (this.contactId) {
                this.$store.dispatch('contacts/show', { id: this.contactId });
            }
        },
        getAttributesByModel() {
            if (this.contactId) {
                this.$store.dispatch('contacts/show', { id: this.contactId });
            }
        },
        openTranscriptModal() {
            this.showTranscriptModal = true;
        },
        onDragEnd() {
            this.dragging = false;
            this.updateUISettings({
                conversation_sidebar_items_order: this.conversationSidebarItems,
            });
        },
        /* to return the serial number by the customer cin if he exists */
        async getIdentityByCin(cin, birthDate) {
            const { appLogicURL } = window.convergenceConfig;
            const response = await axios.get(`${appLogicURL}/insurance-apis/getIdentityByCin`, {
                params: {
                    identifiant: cin,
                    date_naissance: birthDate,
                    type_identifiant: 0,
                },
            });
            if (response.data.matricule_client) {
                this.customerData.serialNumber = response.data.matricule_client ;
                this.customerData.phoneNumber = response.data.phone_number;
                await this.getClientPersonalData(this.customerData.serialNumber);
                await this.getClientContracts(this.customerData.serialNumber);
                await this.getClientSinisters(this.customerData.serialNumber);
                await this.getProductFamilyContracts(this.customerData.serialNumber);
            }
        },
        /* to return the customer personal data by its serial number */
        async getClientPersonalData(serialNumber) {
            const { appLogicURL } = window.convergenceConfig;
            const response = await axios.get(`${appLogicURL}/insurance-apis/GetClientPersonalData`, {
                params: {
                    matricule_client: serialNumber,
                },
            });
            // The returned address data
            const addressData = response.data.adresse;
            this.customerData.address = addressData.Rue1 !== "" ? `${addressData.Rue1}${addressData.Gouvernorat}` : (addressData.Rue2 !== "" ? `${addressData.Rue2}${addressData.Gouvernorat}` : addressData.Gouvernorat);
            this.customerData.email = response.data.email;
            this.customerData.gender = response.data.genre;
            await this.calculateAge(response.data.birthday);
        },
        calculateAge(birthday) {
            const birthYear = birthday.split('/')[2].split(' ')[0];
            const birthMonth = birthday.split('/')[1];
            const birthDay = birthday.split('/')[0];
            const currentDate = new Date();
            let age = currentDate.getFullYear() - birthYear;
            // Check if the birthday has already passed this year
            if (currentDate.getMonth() < birthMonth ||
                (currentDate.getMonth() === birthMonth && currentDate.getDate() < birthDay)) {
                age--;
            }
            this.customerData.age =  age;
        },
        /* to return the url for edit the client */
        async getRedirectUrl(cin) {
            const { appLogicURL } = window.convergenceConfig;
            const response = await axios.get(`${appLogicURL}/gat/ticketUrl`, {
                params: {
                    numero: cin,
                },
            });
            let redirectUrl = response.data.RedirectUrl;
            this.customerData.editUrl = redirectUrl;
        },
        /* to return the number of customer contracts in progress by its serial number */
        async getClientContracts(serialNumber) {
            const { appLogicURL } = window.convergenceConfig;
            const response = await axios.get(`${appLogicURL}/insurance-apis/GetClientContrats`, {
                params: {
                    matricule_client: serialNumber,
                    etatPolice: 1,
                },
            });
            const contracts = response.data;
            this.customerData.currentContractsCount = contracts.length;
            this.allContracts = contracts;
        },
        /* to return the number of customer sinisters in progress by its serial number */
        async getClientSinisters(serialNumber) {
            const { appLogicURL } = window.convergenceConfig;
            const response = await axios.get(`${appLogicURL}/insurance-apis/getAllSinistres`, {
                params: {
                    matricule_client: serialNumber,
                    etatPolice: 1,
                },
            });
            const sinisters = response.data;
            this.customerData.currentSinistersCount = sinisters.length;
            this.allSinisters = sinisters ;
        },
        /* to return the number of customer claims in progress by its serial number */
        async getClientClaims(cin) {
            const { appLogicURL } = window.convergenceConfig;
            const response = await axios.get(`${appLogicURL}/insurance-apis/ticketDetails`, {
                params: {
                    identifiant: cin,
                },
            });
            if (response.data.tickets !== null) {
                const claims = response.data.tickets;
                const activeClaims = claims.filter(claim => claim.statut === "Actif");
                this.customerData.currentClaimsCount = activeClaims.length;
                this.allClaims = activeClaims;
            }
        },
        /* to return the number of current contracts by its product family */
        async getProductFamilyContracts(serialNumber) {
            await this.getAutomobileContracts(serialNumber);
            await this.getIncendieContracts(serialNumber);
            await this.getTransportContracts(serialNumber);
            await this.getVarRisksContracts(serialNumber);
            await this.getHealthContracts(serialNumber);
            await this.getLifeContracts(serialNumber);
            await this.getSpecialRisksContracts(serialNumber);
            await this.getRcContracts(serialNumber);
        },
        /* to return the number of current automobile contracts by its serial number */
        async getAutomobileContracts(serialNumber) {
            const { appLogicURL } = window.convergenceConfig;
            const response = await axios.get(`${appLogicURL}/insurance-apis/GetClientContrats`, {
                params: {
                    matricule_client: serialNumber,
                    etatPolice: 1,
                    productFamily: 1,
                },
            });
            const automobileContracts = response.data;
            this.currentContractsCount.automobile = automobileContracts.length;
        },
        /* to return the number of current incendie contracts by its serial number */
        async getIncendieContracts(serialNumber) {
            const { appLogicURL } = window.convergenceConfig;
            const response = await axios.get(`${appLogicURL}/insurance-apis/GetClientContrats`, {
                params: {
                    matricule_client: serialNumber,
                    etatPolice: 1,
                    productFamily: 3,
                },
            });
            const incendieContracts = response.data;
            this.currentContractsCount.incendie = incendieContracts.length;
        },
        /* to return the number of current transport contracts by its serial number */
        async getTransportContracts(serialNumber) {
            const { appLogicURL } = window.convergenceConfig;
            const response = await axios.get(`${appLogicURL}/insurance-apis/GetClientContrats`, {
                params: {
                    matricule_client: serialNumber,
                    etatPolice: 1,
                    productFamily: 4,
                },
            });
            const transportContracts = response.data;
            this.currentContractsCount.transport = transportContracts.length;
        },
        /* to return the number of current various risks contracts by its serial number */
        async getVarRisksContracts(serialNumber) {
            const { appLogicURL } = window.convergenceConfig;
            const response = await axios.get(`${appLogicURL}/insurance-apis/GetClientContrats`, {
                params: {
                    matricule_client: serialNumber,
                    etatPolice: 1,
                    productFamily: 5,
                },
            });
            const varRisksContracts = response.data;
            this.currentContractsCount.variousRisks = varRisksContracts.length;
        },
        /* to return the number of current health contracts by its serial number */
        async getHealthContracts(serialNumber) {
            const { appLogicURL } = window.convergenceConfig;
            const response = await axios.get(`${appLogicURL}/insurance-apis/GetClientContrats`, {
                params: {
                    matricule_client: serialNumber,
                    etatPolice: 1,
                    productFamily: 6,
                },
            });
            const healthContracts = response.data;
            this.currentContractsCount.health = healthContracts.length;
        },
        /* to return the number of current life contracts by its serial number */
        async getLifeContracts(serialNumber) {
            const { appLogicURL } = window.convergenceConfig;
            const response = await axios.get(`${appLogicURL}/insurance-apis/GetClientContrats`, {
                params: {
                    matricule_client: serialNumber,
                    etatPolice: 1,
                    productFamily: 7,
                },
            });
            const lifeContracts = response.data;
            this.currentContractsCount.life = lifeContracts.length;
        },
        /* to return the number of current special risks contracts by its serial number */
        async getSpecialRisksContracts(serialNumber) {
            const { appLogicURL } = window.convergenceConfig;
            const response = await axios.get(`${appLogicURL}/insurance-apis/GetClientContrats`, {
                params: {
                    matricule_client: serialNumber,
                    etatPolice: 1,
                    productFamily: 8,
                },
            });
            const specialRisksContracts = response.data;
            this.currentContractsCount.specialRisks = specialRisksContracts.length;
        },
        /* to return the number of current special risks contracts by its serial number */
        async getRcContracts(serialNumber) {
            const { appLogicURL } = window.convergenceConfig;
            const response = await axios.get(`${appLogicURL}/insurance-apis/GetClientContrats`, {
                params: {
                    matricule_client: serialNumber,
                    etatPolice: 1,
                    productFamily: 9,
                },
            });
            const rcContracts = response.data;
            this.currentContractsCount.rc = rcContracts.length;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

::-webkit-scrollbar {
  display: none;
}

.contact--panel {
  background: #FFFFFF;
  border: 0.5px solid #483A9D;
  box-shadow: 0px 3px 12px rgb(0 0 0 / 4%);
  border-radius: 8px 0px 8px 8px;
  //border-left: 1px solid var(--color-border);
  font-size: $font-size-small;
  overflow-y: auto;
  overflow: auto;
  position: relative;

  i {
    margin-right: $space-smaller;
  }
}

.list-group {
  .list-group-item {
    background-color: var(--white);
  }
}

::v-deep {
  .contact--profile {
    padding-bottom: var(--space-slab);
    border-bottom: 1px solid var(--color-border);
  }

  .conversation--actions .multiselect-wrap--small {
    .multiselect {
      padding-left: var(--space-medium);
      box-sizing: border-box;
    }

    .multiselect__element {
      span {
        width: 100%;
      }
    }
  }
}

.close-button {
  position: absolute;
  right: $space-two;
  top: $space-slab;
  font-size: $font-size-default;
  color: $color-heading;
  z-index: 9989;
}

.conversation--labels {
  padding: $space-medium;

  .icon {
    margin-right: $space-micro;
    font-size: $font-size-micro;
    color: #fff;
  }

  .label {
    color: #fff;
    padding: 0.2rem;
  }
}

.contact--mute {
  color: $alert-color;
  display: block;
  text-align: left;
}

.contact--actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact-info {
  margin-top: var(--space-two);
}

.contact--panel--container {
  //height: 1000px;
  height: 100%;
  width: 100%;
  border-radius: 8px 0px 8px 8px;
}

.slide {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

/* prefix with transition name */
.slide-fade-enter-active {
  opacity: 0;
  z-index: 10;
}

.slide-fade-leave-active {
  opacity: 0;
}

.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
}</style>
