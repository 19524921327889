export const ATTRIBUTE_MODELS = [
  {
    id: 0,
    option: 'Conversation',
  },
  {
    id: 1,
    option: 'Contact',
  },
];

export const ATTRIBUTE_TYPES = [
  {
    id: 0,
    option: 'Text',
  },
  {
    id: 1,
    option: 'Number',
  },
  {
    id: 4,
    option: 'Link',
  },
  {
    id: 5,
    option: 'Date',
  },
  {
    id: 6,
    option: 'List',
  },
  {
    id: 7,
    option: 'Checkbox',
  },
];

export function updateOptionForAttributeType(language) {
  if (language === 'fr') {
    ATTRIBUTE_TYPES[0].option = 'Texte';
    ATTRIBUTE_TYPES[1].option = 'Nombre';
    ATTRIBUTE_TYPES[2].option = 'Lien';
    ATTRIBUTE_TYPES[3].option = 'Date';
    ATTRIBUTE_TYPES[4].option = 'Liste';
    ATTRIBUTE_TYPES[5].option = 'Boîte à cocher';
  }
  else {
    ATTRIBUTE_TYPES[0].option = 'Text';
    ATTRIBUTE_TYPES[1].option = 'Number';
    ATTRIBUTE_TYPES[2].option = 'Link';
    ATTRIBUTE_TYPES[3].option = 'Date';
    ATTRIBUTE_TYPES[4].option = 'List';
    ATTRIBUTE_TYPES[5].option = 'Checkbox';
  }
}
